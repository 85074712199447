import * as React from "react"

const FadeCircleSmall = props => (
  <svg
    width={373}
    height={373}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={186.5} cy={186.5} r={151.5} stroke="url(#a)" strokeWidth={70} />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(90 0 186.5) scale(186.5)"
      >
        <stop offset={0.891} stopColor="#fff" stopOpacity={0.045} />
        <stop offset={0.932} stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </radialGradient>
    </defs>
  </svg>
)

export default FadeCircleSmall
