import { Integrations } from "@sentry/tracing"

export default {
  api: "/api",
  sentry: {
    dsn: "https://53b5e8f1f6fa4b2f9c43118c7f6a4c39@o329238.ingest.sentry.io/6766427",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  },
}
