import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { resetCursorSize } from "utils/animationUtil"
import { useNav } from "hooks/useNav"
import useFetch from "hooks/useFetch"
import { getTeamMembersList } from "services/teamMembersService"
import useImagePreloader from "hooks/useImagePreloader"

export default function useTeam() {
  const teamRef = useRef()
  const q = gsap.utils.selector(teamRef)
  const { isNavAnimating } = useNav()
  const { data, isLoading } = useFetch(getTeamMembersList)
  const [activeMember, setActiveMember] = useState()
  const [isTeamMemberHover, setIsTeamMemberHover] = useState(false)
  const [featuredTeamMembers, setFeaturedTeamMembers] = useState([])
  const [teamMembers, setTeamMembers] = useState([])

  const [preLoadImageList, setPreLoadImageList] = useState(null)
  const { imagesPreloaded } = useImagePreloader(preLoadImageList)

  useEffect(() => {
    if (isLoading) return

    setFeaturedTeamMembers(data.items.filter(x => x.isFeatured))
    setTeamMembers(data.items.filter(x => !x.isFeatured))
    setPreLoadImageList(data.items.map(x => x.image))
  }, [isLoading])

  useEffect(() => {
    if (!imagesPreloaded || isNavAnimating) return

    gsap
      .timeline()
      .to(q(".team-title span.rotate"), {
        opacity: 1,
        x: 0,
        y: 0,
        stagger: 0.2,
        rotate: -90,
        ease: "back.out(1.5)",
      })
      .to(q(".team-title .slide-in"), {
        opacity: 1,
        x: 0,
        ease: "back.out(1.5)",
      })
      .to(q(".featured-member-list .team-member"), {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        ease: "back.out(1.5)",
        duration: 1,
      })
      .to(q(".team-member-list .team-member"), {
        opacity: 1,
        y: 0,
        ease: "back.out(1.5)",
        duration: 1,
      })

    return () => resetCursorSize()
  }, [imagesPreloaded, isNavAnimating])

  function handleActiveMember(data) {
    if (!data.isFeatured) return

    setActiveMember(data)
  }

  function handleCloseActiveMember() {
    setActiveMember(null)
  }

  return {
    teamRef,
    isLoading,
    isTeamMemberHover,
    activeMember,
    featuredTeamMembers,
    teamMembers,
    setIsTeamMemberHover,
    handleActiveMember,
    handleCloseActiveMember,
  }
}
