import React, { useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import ProjectBackgroundCircle from "components/core/ProjectBackgroundCircle"

function ImageList({ data }) {
  const { arrayString } = data.content

  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [activeImage, setActiveImage] = useState(
    arrayString ? arrayString[0] : null
  )

  function handleChangeSlide(index) {
    setActiveImage(arrayString[index])
    setActiveImageIndex(index)
  }

  return (
    <StyledImageList>
      <ProjectBackgroundCircle left="60%" top="unset" bottom="50%" />
      <Container>
        <Row className="align-items-center">
          <Col xs="auto">{activeImage && <img src={activeImage} />}</Col>
          <Col xs="auto">
            <Row className="mx-n2">
              {arrayString?.map((item, index) => (
                <Col xs="auto" className="px-2" key={index}>
                  <StyledSliderDot
                    onClick={() => handleChangeSlide(index)}
                    active={index === activeImageIndex}
                  >
                    {index + 1}
                  </StyledSliderDot>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledImageList>
  )
}

export default ImageList

const StyledImageList = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  .container {
    position: relative;
    z-index: 50;
  }
`

const StyledSliderDot = styled.div`
  width: 34px;
  height: 34px;
  background: ${props => (props.active ? vars.black : vars.grey_300)};
  color: ${vars.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: ${vars.secondaryFont};
  user-select: none;
`
