import config from "config"
import httpService from "services/httpService"

const endpoint = `${config.api}/TeamMembers`

export async function getTeamMembersList() {
  const data = await httpService.get(`${endpoint}/TeamMembersList`)
  return data
}

export async function getAdvisorsList() {
  const data = await httpService.get(`${endpoint}/AdvisorsList`)
  return data
}
