import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Header from "components/core/Header"
import GlobalStyles from "styles/globalStyles"
import Footer from "components/core/Footer"
import { useUser } from "hooks/useUser"
import DefaultLogo from "components/svg/DefaultLogo"

function Layout({ children }) {
  const { pathname } = useLocation()
  const { user } = useUser()

  //* Scrolls the user to the top on every page transition
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // Remove layout for admin area, it has it's own
  if (/\/admin/gi.test(pathname)) return children

  return (
    <LayoutContainer
      className="main-container"
      id="main-container"
      data-scroll-container
    >
      <Helmet></Helmet>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const StyledHoldingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`
