import React, { useState, useEffect, useRef } from "react"
import { useLocation, useHistory } from "react-router"
import { toastError } from "utils/toastUtil"

export default function useFetch(fn) {
  const isMounted = useRef(true)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const location = useLocation()
  const history = useHistory()

  // Fetch page data on mount, or if the page changes (refreshes page data when navigating to the same page)
  useEffect(() => {
    onMount()
  }, [location.key])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onMount() {
    const response = await fn()
    console.log("response", response)

    if (!isMounted.current) return
    if (response.is404) return handle404()
    if (!response.isSuccess) return handleError(response)

    setData(response)
    setIsLoading(false)
  }

  function handleError(response) {
    toastError(error)
    console.log(error)
    if (!isMounted.current) return
    setIsLoading(false)
    setError(response.responseMessage)
  }

  function handle404() {
    console.log("404")
    history.push("/not-found")
  }

  return {
    data,
    isLoading,
    error,
    setData,
    setIsLoading,
    setError,
    handleError,
  }
}
