import * as React from "react"

function LinkedInIcon({ color = "#242424", ...rest }) {
  return (
    <svg width={27} height={27} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx={13.5} cy={13.5} r={13.5} fill="#fff" />
      <g clipPath="url(#a)" fill={color}>
        <path d="M19.996 19H20v-4.768c0-2.333-.502-4.13-3.228-4.13-1.311 0-2.19.72-2.55 1.402h-.038V10.32h-2.585V19h2.692v-4.298c0-1.132.214-2.226 1.616-2.226 1.38 0 1.4 1.291 1.4 2.298V19h2.69ZM7.215 10.32H9.91V19H7.215v-8.68ZM8.561 6C7.7 6 7 6.7 7 7.561c0 .862.7 1.576 1.561 1.576.862 0 1.561-.714 1.561-1.576 0-.862-.7-1.561-1.56-1.561Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(7 6)" d="M0 0h13v13H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedInIcon
