import * as React from "react"

const ArrowUpIcon = props => (
  <svg
    width={8}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.354.646a.5.5 0 0 0-.708 0L.464 3.828a.5.5 0 1 0 .708.708L4 1.707l2.828 2.829a.5.5 0 1 0 .708-.708L4.354.646ZM4.5 9V1h-1v8h1Z"
      fill="#fff"
    />
  </svg>
)

export default ArrowUpIcon
