import React, { useState } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import ProjectBackgroundCircle from "components/core/ProjectBackgroundCircle"

function ContentSlider({ data }) {
  const { title, quote, quoteAuthor, quoteAuthorJobTitle, contentSliderList } =
    data.content

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [activeSlide, setActiveSlide] = useState(
    contentSliderList ? contentSliderList[0] : null
  )

  function handleChangeSlide(index) {
    setActiveSlide(contentSliderList[index])
    setActiveSlideIndex(index)
  }

  return (
    <StyledContentSlider>
      <ProjectBackgroundCircle
        top="50%"
        left="36%"
        colour={vars.paleGreen}
        centerVertically
      />
      <Container fluid>
        <Row className="align-items-center">
          <Col lg="8">
            <Row className="align-items-center">
              <Col>
                <Row className="mx-n2">
                  {contentSliderList.map((item, index) => (
                    <Col xs="auto" className="px-2" key={index}>
                      <StyledSliderDot
                        onClick={() => handleChangeSlide(index)}
                        active={index === activeSlideIndex}
                      >
                        {index + 1}
                      </StyledSliderDot>
                    </Col>
                  ))}
                </Row>
                {activeSlide && (
                  <>
                    <div
                      className="mt-4 slide-content"
                      dangerouslySetInnerHTML={{
                        __html: activeSlide.content,
                      }}
                    />
                  </>
                )}
              </Col>
              <Col>
                {activeSlide && (
                  <img src={activeSlide.image} className="img-fluid" />
                )}
              </Col>
            </Row>
          </Col>
          <Col lg="4">
            {title && <h2 className="gsap-slide-left-3">{title}</h2>}
            <div className="quote gsap-slide-left-1">
              {quote && <blockquote>“{quote}”</blockquote>}
              {quoteAuthor && (
                <p>
                  {quoteAuthor}
                  {quoteAuthorJobTitle && (
                    <>
                      <br />
                      {quoteAuthorJobTitle}
                    </>
                  )}
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </StyledContentSlider>
  )
}

export default ContentSlider

const StyledContentSlider = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 5vw;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 89px;
    line-height: 84px;
    letter-spacing: 4px;
    margin-left: 10rem;
  }

  .quote {
    position: relative;
    left: 20rem;
  }

  .slide-content {
    max-width: 375px;
  }

  .container-fluid {
    position: relative;
    z-index: 50;
  }

  blockquote {
    font-family: ${vars.tertiaryFont};
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
    margin: 0 0 2rem 0;
    max-width: 430px;
  }
`

const StyledSliderDot = styled.div`
  width: 34px;
  height: 34px;
  background: ${props => (props.active ? vars.black : vars.grey_300)};
  color: ${vars.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: ${vars.secondaryFont};
  user-select: none;
`
