import React from "react"
import styled from "styled-components"
import vars from "styles/variables"
import { HideBodyOverflow } from "styles/globalStyles"
import useHome from "hooks/useHome"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import Logo from "components/svg/DefaultLogo"
import { homeCoreImages } from "utils/imageUtil"
import { useFooter } from "hooks/useFooter"
import Seo from "components/common/Seo"
import ParticleAnimation from "components/core/ParticleAnimation"

export default function HomePage() {
  const {
    homeRef,
    bgCanvasRef,
    innerCircleCanvasRef,
    middleCircleCanvasRef,
    outerCircleCanvasRef,
    bgImage,
    homeContainerClass,
    innerCircleData,
    showWaterCircles,
    showWeAre,
    showLogo,
    showCoreContent,
    handleCircleClick,
    handleCircleEnter,
    handleCircleLeave,
  } = useHome()

  const { handleFooterOpen } = useFooter()

  return (
    <StyledHome ref={homeRef} className={homeContainerClass}>
      <Seo title="Home" />
      <HideBodyOverflow />
      <StyledBackground className="home-bg-image">
        <ParticleAnimation />
      </StyledBackground>
      <StyledCircleContainer>
        <StyledCoreOuter
          data-index={3}
          data-default-clip-path={46}
          className={`dynamic-circle dynamic-circle-3 ${
            showWaterCircles ? "show-water" : ""
          }`}
          onMouseEnter={e =>
            handleCircleEnter(e, { title: "<h2>Our advisors</h2>" })
          }
          onMouseLeave={handleCircleLeave}
          onClick={() => handleCircleClick("/advisors")}
          onTouchStart={e =>
            handleCircleEnter(e, { title: "<h2>Our advisors</h2>" })
          }
          image={homeCoreImages.outerLarge}
        >
          <canvas
            ref={outerCircleCanvasRef}
            style={{ width: "100%", height: "100%" }}
          />
        </StyledCoreOuter>
        <StyledCoreMiddle
          data-index={2}
          data-default-clip-path={36}
          className={`dynamic-circle dynamic-circle-2 ${
            showWaterCircles ? "show-water" : ""
          }`}
          onMouseEnter={e =>
            handleCircleEnter(e, { title: "<h2>Our people</h2>" })
          }
          onMouseLeave={handleCircleLeave}
          onClick={() => handleCircleClick("/meet-the-team")}
          image={homeCoreImages.middleSmall}
        >
          <canvas
            ref={middleCircleCanvasRef}
            style={{ width: "100%", height: "100%" }}
          />
        </StyledCoreMiddle>
        <StyledCoreInner
          data-index={1}
          data-default-clip-path={26}
          className={`dynamic-circle dynamic-circle-1 ${
            showWaterCircles ? "show-water" : ""
          }`}
          onMouseEnter={e =>
            handleCircleEnter(e, { title: "<h2>About us</h2>" })
          }
          onMouseLeave={handleCircleLeave}
          onClick={() => handleCircleClick("/about-us")}
          image={homeCoreImages.innerSmall}
        >
          <canvas
            ref={innerCircleCanvasRef}
            style={{ width: "100%", height: "100%" }}
          />
        </StyledCoreInner>
        <StyledInfoCircle className="inner-circle"></StyledInfoCircle>
        <StyledCoreContent className="core-content">
          {homeContainerClass === "active" && (
            <div dangerouslySetInnerHTML={{ __html: innerCircleData?.title }} />
          )}

          <div
            className={`default-core-content ${
              homeContainerClass === "active" ? "d-none" : ""
            }`}
          >
            <p
              className={`default-core-content-we-are ${
                !showWeAre ? "d-none" : ""
              }`}
            >
              We are
            </p>

            <Logo
              width={182}
              height={30}
              className={`default-core-logo ${!showLogo ? "d-none" : ""}`}
              style={!showWeAre ? { transform: "unset" } : {}}
            />

            <p
              className={`default-core-content-wrapper ${
                !showCoreContent ? "d-none" : ""
              }`}
            >
              Design <strong>x</strong> Data
            </p>
          </div>
        </StyledCoreContent>
        <StyledCircleThin className="circle-thin circle-breath" />
        <StyledFadeCircle
          className="fade-circle-1 circle-breath"
          src={require("assets/images/fade-circle-1.svg")}
        />
        <StyledFadeCircle
          className="fade-circle-2 circle-breath"
          src={require("assets/images/fade-circle-2.svg")}
        />
      </StyledCircleContainer>
      <StyledLocations
        onClick={e => {
          e.preventDefault()
          handleFooterOpen()
        }}
        onMouseEnter={enlargeCursorSize}
        onMouseLeave={resetCursorSize}
      >
        London | Riyadh
      </StyledLocations>
    </StyledHome>
  )
}

const StyledHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .default-core-content,
  .default-core-content-wrapper {
    animation: fadeIn 2s;
    line-height: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dynamic-circle {
    canvas {
      //opacity: 0;
      //transition: opacity ease-in-out 1000ms;
    }
  }

  .show-water {
    canvas {
      opacity: 1;
    }
  }

  &.active {
    .default-core-content {
      animation: unset;
    }

    .dynamic-circle {
      &:after {
        content: "";
        background: ${vars.dynamicColour("0.7")};
      }
    }
  }
`

const StyledCircleContainer = styled.div`
  overflow: hidden;

  .default-core-content-wrapper {
    font-size: 28px;
    margin-bottom: 0;
    line-height: 1;
  }

  .dynamic-circle {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 106%;
    display: inline-block;
    position: absolute;
    top: 50%;
    aspect-ratio: 1 / 1;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    max-height: 929px;

    &:hover:after,
    &.active:after {
      background: ${vars.dynamicColour("0")} !important;
    }

    &:after {
      content: "";
      transition: 1s ease-in-out;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const StyledCoreOuter = styled.div`
  background-image: url(${props => props.image});
  background-color: white;
  clip-path: circle(0%);
  z-index: 10;
  min-height: 600px;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none !important;
  }
`

const StyledCoreMiddle = styled.div`
  background-image: url(${props => props.image});
  background-color: white;
  clip-path: circle(0%);
  z-index: 20;
  min-height: 500px;
`

const StyledCoreInner = styled.div`
  background-image: url(${props => props.image});
  background-color: white;
  clip-path: circle(0%);
  z-index: 30;
  min-height: 400px;
`

const StyledFadeCircle = styled.img`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  height: 20%;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`

const StyledCircleThin = styled.div`
  height: 20%;
  border: 2px solid #fcfcfc;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`

const StyledInfoCircle = styled.div`
  background: ${vars.dynamicColour()};
  height: 22%;
  max-height: 320px;
  min-height: 230px;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 40;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  aspect-ratio: 1 / 1;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.4;
  overflow: hidden;
  opacity: 0;
  border: 1px solid #cccccc;
`

const StyledCoreContent = styled(StyledInfoCircle)`
  background: none;
  height: 38%;
  z-index: 50;
  font-size: 20px;
  line-height: 1.5;
  padding: 0 2%;
  flex-direction: column;
  border: none;

  h2 {
    font-weight: normal;
    margin: 0;
    font-size: 28px;
  }
`

const StyledBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 1;
  background: url(${props => props.bgImage});

  .home-bg-image-overlay {
    width: 100%;
    height: 100%;
    background: ${vars.dynamicColour(0.6)};
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }
`

const StyledLocations = styled.a`
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 16px;
  z-index: 100;
`
