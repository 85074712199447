import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from "react"
import { gsap } from "gsap"
import { attachIt } from "utils/animationUtil"

const Context = createContext()

// Provider component that wraps your app and makes our object
// available to any child component
export function FooterProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

// Hook for child components to get the object
// and re-render when it changes
export const useFooter = () => {
  return useContext(Context)
}

// Provider hook that creates object and handles state
function useProvider() {
  const footerRef = useRef()
  const q = gsap.utils.selector(footerRef)
  const footerAnimations = useRef()
  const [isFooterOpen, setIsFooterOpen] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [isShowBackToTop, setIsShowBackToTop] = useState(true)

  useEffect(() => {
    footerAnimations.current = {
      toggleFooter: isOpen => {
        gsap
          .timeline()
          .to(q(".footer-container"), {
            y: isOpen ? "0" : "100vh",
            //ease: isOpen ? "expo.out" : "power1.in",
            duration: 0.5,
          })
          .to(
            q(".footer-circle"),
            {
              borderRadius: isOpen ? "0" : "50%",
              //ease: isOpen ? "power1.in" : "expo.out",
              duration: 0.3,
            },
            0
          )
      },
      toggleShowMore: isOpen => {
        gsap.to(q(".footer-circle"), {
          borderRadius: isOpen ? "0" : "50%",
          ease: isOpen ? "power1.in" : "expo.out",
          duration: 0.3,
        })
      },
    }
  }, [])

  function handleFooterToggle() {
    setIsFooterOpen(x => {
      footerAnimations.current.toggleFooter(!x)
      return !x
    })
  }

  function handleShowMoreToggle() {
    setIsShowMore(x => {
      footerAnimations.current.toggleShowMore(!x)
      return !x
    })
  }

  function handleFooterClose() {
    setIsFooterOpen(x => {
      footerAnimations.current.toggleFooter(false)
      return false
    })
  }

  function handleFooterOpen() {
    setIsFooterOpen(x => {
      footerAnimations.current.toggleFooter(true)
      return true
    })
  }

  function handleShowBackToTop(show = false) {
    setIsShowBackToTop(show)
  }

  return {
    footerRef,
    isFooterOpen,
    isShowMore,
    isShowBackToTop,
    handleFooterToggle,
    handleShowMoreToggle,
    handleFooterOpen,
    handleFooterClose,
    handleShowBackToTop,
  }
}
