import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import vars from "styles/variables"
import LoadingIcon from "components/common/LoadingIcon"

const Button = ({
  children,
  isLoading,
  styleType = "primary",
  type = "button",
  to,
  isFullWidth,
  isLarge,
  minWidth,
  ...other
}) => {
  if (!to) {
    // No property for 'to' has been passed, return a form button
    return (
      <StyledButton
        styleType={styleType}
        type={type}
        isLoading={isLoading}
        disabled={isLoading}
        isFullWidth={isFullWidth}
        isLarge={isLarge}
        minWidth={minWidth}
        {...other}
      >
        <span className="button-text">{children}</span>
        <StyledLoadingContainer isLoading={isLoading}>
          <LoadingIcon />
        </StyledLoadingContainer>
      </StyledButton>
    )
  } else {
    // The 'to' property has a value, retrun an internal or external link.
    // The below regex assumes that any internal link will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to)

    if (internal) {
      return (
        <StyledButton
          as={({
            styleType,
            isLoading,
            isFullWidth,
            isLarge,
            minWidth,
            ...rest
          }) => <Link {...rest} />}
          to={to}
          styleType={styleType}
          isFullWidth={isFullWidth}
          isLarge={isLarge}
          minWidth={minWidth}
          {...other}
        >
          {children}
        </StyledButton>
      )
    } else {
      return (
        <StyledButton
          as="a"
          href={to}
          styleType={styleType}
          isFullWidth={isFullWidth}
          isLarge={isLarge}
          minWidth={minWidth}
          {...other}
        >
          {children}
        </StyledButton>
      )
    }
  }
}

export default Button

const ButtonStyle = css`
  font-size: ${props => (props.isLarge ? "18px" : "14px")};
  ${props => (props.isFullWidth ? "width:100%;" : "")}
  ${props => (props.minWidth ? `min-width:${props.minWidth}px;` : "")}
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 10px 20px;
  cursor: pointer;
  line-height: 1;
  border: 1px solid ${vars.white};
  border-radius: 20px;
  color: ${vars.white};
  opacity: 1;
  transition: ease-in-out 500ms;
  background: ${props =>
    props.styleType === "black" ? vars.black : vars.grey_600};
  font-family: ${vars.primaryFont};
  font-weight: normal;
  text-transform: uppercase;
  position: relative;
  user-select: none;
  z-index: 10;

  .button-text {
    visibility: ${props => (props.isLoading ? "hidden" : "visible")};
  }

  &:hover,
  &:disabled,
  &[disabled] {
    background: ${vars.grey_600};
  }
`

const StyledButton = styled(
  ({ styleType, isLoading, isFullWidth, isLarge, minWidth, ...rest }) => (
    <button {...rest} />
  )
)`
  ${ButtonStyle}
`
const StyledLoadingContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: ${props => (props.isLoading ? "flex" : "none")};
`
