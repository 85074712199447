import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import Link from "components/common/Link"
import ArrowUpIcon from "components/svg/ArrowUpIcon"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import ContactForm from "components/core/ContactForm"
import { HideBodyOverflow } from "styles/globalStyles"
import LinkedInIcon from "components/svg/LinkedInIcon"
import InstagramIcon from "components/svg/InstagramIcon"
import { useFooter } from "hooks/useFooter"

function Footer() {
  const { isFooterOpen, isShowMore, footerRef, handleFooterToggle } =
    useFooter()

  return (
    <StyledFooterContainer ref={footerRef}>
      {isFooterOpen && <HideBodyOverflow />}

      <StyledFooterToggleButton
        onMouseEnter={enlargeCursorSize}
        onMouseLeave={resetCursorSize}
        onClick={handleFooterToggle}
        className={`${isFooterOpen ? "active" : ""}`}
      >
        {isFooterOpen ? "Close" : "Contact Us"}
        <ArrowUpIcon />
      </StyledFooterToggleButton>

      <StyledFooter className="footer-container">
        <StyledFooterCircle className="footer-circle" />
        <StyledFooterContent isShowMore={isShowMore}>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col md="6" className="mb-4 col-xl">
                    <p className="mb-3 text-uppercase">Email</p>
                    <a
                      href="mailto:studio@io-atelier.com"
                      className="cta-footer-link"
                      target="_blank"
                    >
                      studio@io-atelier.com
                    </a>
                  </Col>
                  <Col md="6" className="mb-4 col-xl">
                    <p className="mb-3 text-uppercase">Phone</p>
                    <p>
                      <a href="tel:+447800582169" className="cta-footer-link">
                        +44 (0) 7800 582169
                      </a>
                    </p>
                    <a href="tel:+966544281612" className="cta-footer-link">
                      +966 (0) 54 428 1612
                    </a>
                  </Col>
                  <Col lg="12" className="mb-4 col-xl">
                    <Row>
                      <Col md="6" xl="12" className="mb-2 ">
                        <p className="mb-3 text-uppercase mr-sm-3 mr-md-0">
                          London
                        </p>
                        <p>
                          Mindspace Shoreditch, 9 Appold Street, London, EC2A
                          2AP
                        </p>
                      </Col>
                      <Col>
                        <p className="mb-3 text-uppercase mr-sm-3 mr-md-0">
                          Riyadh
                        </p>
                        <p>Kingdom of Saudi Arabia</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={4}>
                    <p className="mb-4">
                      <span className="d-block mb-2 text-uppercase">
                        Steven Charlton
                      </span>
                      <a href="mailto:steven@io-atelier.com" target="_blank">
                        steven@io-atelier.com
                      </a>
                    </p>
                    <p className="mb-4">
                      <span className="d-block mb-2 text-uppercase">
                        Jeremy Brown
                      </span>
                      <a href="mailto:jeremy@io-atelier.com" target="_blank">
                        jeremy@io-atelier.com
                      </a>
                    </p>
                    <p className="mb-4">
                      <span className="d-block mb-2 text-uppercase">
                        Mehdi Jelokhani
                      </span>
                      <a href="mailto:mehdi@io-atelier.com" target="_blank">
                        mehdi@io-atelier.com
                      </a>
                    </p>
                    <p className="mb-4">
                      <span className="d-block mb-2 text-uppercase">
                        Abdul Jabbar
                      </span>
                      <a href="mailto:abdul@io-atelier.com" target="_blank">
                        abdul@io-atelier.com
                      </a>
                    </p>
                  </Col>
                  <Col>
                    <p className="text-uppercase mb-3 mt-3 mt-lg-0">Enquire</p>
                    <ContactForm handleFooterToggle={handleFooterToggle} />
                  </Col>
                </Row>
              </Col>
              {/* <Col
                xs="12"
                lg="auto"
                className={"align-self-end"}
              >
                <Row>
                  <Col
                    xs="auto"
                    className={`mb-4 ${isShowMore ? "d-none d-lg-block" : ""}`}
                  >
                    <Button onClick={handleShowMoreToggle} minWidth={159}>
                      {isShowMore ? "Show Less" : "Enquire"}
                    </Button>
                  </Col>
                </Row>
              </Col> */}
            </Row>
            <Row className="footer-bottom align-items-center">
              <Col xs="auto" className="order-2 order-md-1">
                <a
                  href="https://www.inspiredagency.co.uk/"
                  target="_blank"
                  className="small-footer-link"
                >
                  A website by <span>Inspired Agency</span>
                </a>
              </Col>
              <Col xs="auto" className="order-3 order-md-2">
                <Link
                  to="/cookie-policy"
                  className="small-footer-link"
                  onClick={handleFooterToggle}
                >
                  View our <span>Cookie Policy</span>
                </Link>
              </Col>
              <Col xs="12" className="col-md order-1 order-md-3 mb-3 mb-md-0">
                <Row noGutters className="align-items-center">
                  <Col xs="auto">
                    <span className="text-uppercase">Connect</span>
                  </Col>
                  <Col xs="auto" className="mx-3">
                    <a
                      href="https://www.linkedin.com/company/i-o-atelier/"
                      target="_blank"
                      className="d-flex"
                    >
                      <LinkedInIcon />
                    </a>
                  </Col>
                  <Col xs="auto">
                    <a
                      href="https://www.instagram.com/i.o_atelier/"
                      target="_blank"
                      className="d-flex"
                    >
                      <InstagramIcon />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </StyledFooterContent>
      </StyledFooter>
    </StyledFooterContainer>
  )
}

export default Footer

const StyledFooterContainer = styled.div`
  line-height: 1.4;
`

const StyledFooterToggleButton = styled.div`
  position: fixed;
  background: ${vars.grey_600};
  color: ${vars.white};
  padding: 10px 20px;
  border-radius: 56px;
  text-transform: uppercase;
  z-index: 300;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1rem;
  bottom: 20px;
  right: 20px;
  line-height: 1;
  font-size: 14px;

  &.active {
    svg {
      transform: rotate(-180deg);
    }
  }

  svg {
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  }
`

const StyledFooterCircle = styled.div`
  position: absolute;
  left: -30%;
  width: 160%;
  display: block;
  background: ${vars.grey_700};
  border-radius: 50%;
  top: 0;
  aspect-ratio: 1 / 1;

  @media (max-width: ${vars.screen_sm_min}) {
    display: none;
  }
`

const StyledFooter = styled.footer`
  position: fixed;
  z-index: 290;
  left: 0;
  width: 100%;
  display: block;
  /* top: 0; */
  bottom: 0;
  transform: translate(0px, 100vh);

  @media (max-width: ${vars.screen_sm_min}) {
    background: ${vars.grey_700};
    border-radius: 0 !important;
    z-index: 295;
  }

  .cta-footer-link {
    font-size: 26px;

    @media (max-width: ${vars.screen_md_max}) and (min-width: ${vars.screen_md_min}) {
      font-size: 22px;
    }
  }

  .small-footer-link {
    font-size: 12px;
    opacity: 0.8;

    span {
      text-decoration: underline;
    }
  }
`

const StyledFooterContent = styled.div`
  color: ${vars.white};
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;

  @media (min-width: ${vars.screen_xl_min}) and (min-height: 610px) {
    align-items: center;
    justify-content: center;
  }

  height: 100vh;
  overflow-y: scroll;

  @media (max-width: ${vars.screen_md_max}) {
    padding-top: 40px;
    display: block;
  }

  @media (max-width: ${vars.screen_sm_min}) {
    padding-top: 90px;
    padding-bottom: 70px;
    height: 100vh;
  }

  a {
    color: ${vars.white};
  }

  .footer-bottom {
    margin-top: 0 !important;

    @media (min-width: ${vars.screen_xl_min}) {
      margin-top: -62px;
    }
  }
`
