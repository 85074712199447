import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { resetCursorSize } from "utils/animationUtil"
import { useNav } from "hooks/useNav"
import { useFooter } from "hooks/useFooter"

export default function useEnvironment(isReady) {
  const environmentRef = useRef()
  const q = gsap.utils.selector(environmentRef)
  const { isNavAnimating } = useNav()
  const { handleShowBackToTop } = useFooter()

  useEffect(() => {
    handleShowBackToTop(true)
    return handleShowBackToTop
  }, [])

  useEffect(() => {
    if (!isReady || isNavAnimating) return

    gsap
      .timeline()
      .to(q(".enviro-title span"), {
        opacity: 1,
        x: 0,
        y: 0,
        stagger: 0.2,
        rotate: 0,
        ease: "back.out(1.5)",
      })
      .to(
        q(".enviro-quote"),
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        0.5
      )
      .to(
        q(".enviro-banner"),
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 1,
        },
        0.5
      )
      .to(
        q(".carbon-title"),
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        0.7
      )

    return () => {
      resetCursorSize()
    }
  }, [isReady, isNavAnimating])

  return {
    environmentRef,
  }
}
