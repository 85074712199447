import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import useImagePreloader from "hooks/useImagePreloader"
import FadeCircleSmall from "components/svg/FadeCircleSmall"
import ImgIphoneStock from "assets/images/iphone-stocks.png"
import ImgIphoneNike from "assets/images/iphone-nike.png"
import ImgIphoneFootball from "assets/images/iphone-football.png"
import ImgIphoneFarmer from "assets/images/iphone-farmer.png"
import ImgIphoneMusic from "assets/images/iphone-music.png"
import ImgIphoneNetflix from "assets/images/iphone-netflix.png"
import IconStock from "assets/icons/icon-stocks.png"
import IconNike from "assets/icons/icon-nike.png"
import IconFootball from "assets/icons/icon-football.png"
import IconFarmer from "assets/icons/icon-farmer.png"
import IconMusic from "assets/icons/icon-music.png"
import IconNetflix from "assets/icons/icon-netflix.png"
import ArrowLeftLongIcon from "components/svg/ArrowLeftLongIcon"
import ArrowRightLongIcon from "components/svg/ArrowRightLongIcon"
import vars from "styles/variables"

function DataCreativityCarousel() {
  const autoPlayRef = useRef()
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const [activeItem, setActiveItem] = useState(data[0])
  const [autoPlayItemId, setAutoPlayItemId] = useState(1)
  //const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    autoPlayRef.current = setInterval(() => {
      setAutoPlayItemId(prev => (data.length === prev ? 1 : prev + 1))
    }, 6000)
    return () => clearInterval(autoPlayRef.current)
  }, [])

  useEffect(() => {
    handleAutoPlayActiveItem(autoPlayItemId)
  }, [autoPlayItemId])

  //! Added functionality to fade between items but it looks better without. Leaving this in for now in case the client requests it.
  // useEffect(() => {
  //   setTimeout(() => setOpacity(1), 1000)
  // }, [activeItem])

  function handleAutoPlayActiveItem(id) {
    //await animateActiveItemOut()

    const item = data.find(x => x.id === id)
    setActiveItem(item)
  }

  function handleActiveItem(id) {
    const item = data.find(x => x.id === id)
    updateActiveItem(item)
  }

  function handleNext() {
    const currentIndex = data.indexOf(activeItem)
    const index = (currentIndex + 1) % data.length
    updateActiveItem(data[index])
  }

  function handlePrev() {
    const currentIndex = data.indexOf(activeItem)
    const index = (currentIndex + data.length - 1) % data.length
    updateActiveItem(data[index])
  }

  function updateActiveItem(item) {
    clearInterval(autoPlayRef.current)
    setActiveItem(item)
  }

  // async function animateActiveItemOut() {
  //   setOpacity(0)
  //   await delay(300)
  // }

  return (
    <StyledCarousel
      data-scroll
      data-scroll-speed="-2"
      data-scroll-direction="horizontal"
    >
      {imagesPreloaded && (
        <>
          {activeItem && (
            // <div className="fade-animation" style={{ opacity }}>
            <div>
              <StyledCarouselImage
                key="carousel-image"
                src={activeItem.imageSrc}
                className="img-fluid"
              />
              <StyledContentBorder />
              <StyledContent>
                <FadeCircleSmall className="fade-circle" />
                <StyledInnerContent>
                  <div>
                    <StyledCarouselIcon
                      src={activeItem.iconSrc}
                      key="carousel-icon-image"
                      className="img-fluid"
                    />

                    <p>{activeItem.content}</p>

                    <ArrowLeftLongIcon onClick={handlePrev} className="mr-2" />
                    <ArrowRightLongIcon onClick={handleNext} className="ml-2" />
                  </div>
                </StyledInnerContent>
              </StyledContent>
            </div>
          )}
          <StyledControls>
            {data.map(item => (
              <StyledControlDot
                key={item.id}
                className={item.id === activeItem.id ? "active" : ""}
                onClick={() => handleActiveItem(item.id)}
              />
            ))}
          </StyledControls>
        </>
      )}
    </StyledCarousel>
  )
}

export default DataCreativityCarousel

const preloadSrcList = [
  ImgIphoneStock,
  ImgIphoneNike,
  ImgIphoneFootball,
  ImgIphoneFarmer,
  ImgIphoneMusic,
  ImgIphoneNetflix,
  IconStock,
  IconNike,
  IconFootball,
  IconFarmer,
  IconMusic,
  IconNetflix,
]

const data = [
  {
    id: 1,
    content:
      "A stockbroker wouldn't buy a stock without looking at past performance data",
    imageSrc: ImgIphoneStock,
    iconSrc: IconStock,
  },
  {
    id: 2,
    content:
      "NIKE don't just design a trainer. They analyse who the consumer is and then design the trainer",
    imageSrc: ImgIphoneNike,
    iconSrc: IconNike,
  },
  {
    id: 3,
    content:
      "A football team wouldn't buy a player without looking at the player's data",
    imageSrc: ImgIphoneFootball,
    iconSrc: IconFootball,
  },
  {
    id: 4,
    content: "Even farmers are using data to improve yields",
    imageSrc: ImgIphoneFarmer,
    iconSrc: IconFarmer,
  },
  {
    id: 5,
    content:
      "Music producers now tailor music for the audience using data - before music was made and radio decided what we listen to.",
    imageSrc: ImgIphoneMusic,
    iconSrc: IconMusic,
  },
  {
    id: 6,
    content: `Netflix's concept for "Stanger Things" was based on research data of trends to understand what audiences wanted right now.`,
    imageSrc: ImgIphoneNetflix,
    iconSrc: IconNetflix,
  },
]

const StyledCarousel = styled.div`
  position: relative;
  min-height: 540px;
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 50px;

  @media (max-width: ${vars.screen_xs_max}) {
    min-height: 584px;
    margin-bottom: 100px;
  }

  .fade-animation {
    transition: ease-in-out 200ms;
    opacity: 0;
  }
`

const StyledContent = styled.div`
  position: absolute;
  width: 398px;
  height: 398px;
  background: #e9f2eb;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  z-index: 20;

  @media (max-width: ${vars.screen_xs_max}) {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -70px;
  }

  svg.fade-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledInnerContent = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
`

const StyledContentBorder = styled.div`
  position: absolute;
  bottom: -26px;
  left: -26px;
  width: 452px;
  height: 452px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  z-index: 1;

  @media (max-width: ${vars.screen_xs_max}) {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -95px;
  }
`

const StyledControls = styled.div`
  display: flex;
  margin-top: 20px;
  position: relative;
  z-index: 30;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`

const StyledControlDot = styled.div`
  width: 12px;
  height: 12px;
  background: #e2e2e2;
  padding: 8px;
  border-radius: 50%;
  border: 10px solid white;

  &:hover {
    background: black;
  }

  &.active {
    border-color: #f7f7f7;
  }
`

const StyledCarouselImage = styled.img`
  position: relative;
  z-index: 10;
  width: 377px;
`

const StyledCarouselIcon = styled.img`
  width: 92px;
  height: 92px;
`
