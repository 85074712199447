import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"
import {
  Scene,
  OrthographicCamera,
  WebGLRenderer,
  TextureLoader,
  LinearFilter,
  ShaderMaterial,
  PlaneGeometry,
  Mesh,
} from "three"

//import createWorker from "offscreen-canvas/create-worker"

// Functionality taken from below github project and converted for use in React.
// https://github.com/SaboSuke/ripple-hover-effect

export default function useWaterRipple(props) {
  const workerRef = useRef(null)
  const startRef = useRef()
  const stopRef = useRef()

  useEffect(() => {
    //TODO: Unfinished service worker functionality
    // console.log("canvas", props.canvas)
    // if (!workerRef.current && props.canvas) {
    //   console.log("INIT Worker")
    //   workerRef.current = createWorker(props.canvas, "/worker.js", e => {
    //     // Messages from the worker
    //     console.log("Messages from the worker", e)
    //   })
    // }
    //TODO: ---

    //if (!props.isReady) return
    if (!props.canvas) return

    let vertex = `
        varying vec2 vUv;

        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `

    let fragment = `
        uniform sampler2D image;
        uniform sampler2D image2;
        uniform float time;
        uniform float mouseOver;
        uniform float intensity;
        uniform float strength;
        uniform float area;
        uniform float waveSpeed;
        varying vec2 vUv;
        #define NUM_OCTAVES 5
        float rand(vec2 n) {
            return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
        }
        float noise(vec2 p) {
            vec2 ip = floor(p);
            vec2 u = fract(p);
            u = u*u*(3.0-2.0*u);

            float res = mix(
                mix(rand(ip),rand(ip+vec2(1.0,0.0)),u.x),
                mix(rand(ip+vec2(0.0,1.0)),rand(ip+vec2(1.0,1.0)),u.x),u.y);
            return res*res;
        }
        float fbm(vec2 x) {
            float v = 0.0;
            float a = 0.5;
            vec2 shift = vec2(100);
            // Rotate to reduce axial bias
            mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
            for (int i = 0; i < NUM_OCTAVES; ++i) {
                v += a * noise(x);
                x = rot * x * 2.0 + shift;
                a *= 0.5;
            }
            return v;
        }
        void main(void) {
            vec2 uv = vUv;

            vec2 surface = strength * vec2(
                mix(-0.1, 0.2, fbm(5.*uv + waveSpeed * time)),
                mix(-0.1, 0.2, fbm(5.*uv + waveSpeed * time))
            );
            uv += mouseOver * intensity * refract(
                vec2(0, 0),
                surface,
                1.0 / 1.333
            );
            vec3 _texture = texture2D(image, uv).rgb;
            gl_FragColor = vec4(_texture,1.0);
        }
    `

    const rand = function (a, b) {
      return a + (b - a) * Math.random()
    }

    let time = 0
    let mouseOver = 0
    let playhead = rand(1, 2)

    const parentObj = document.querySelector(props.parent)
    const parent = parentObj || console.warn("no parent!")
    const intensity = props.intensity || 0.5
    const strength = props.strength || 1
    const area = props.area || 2
    const waveSpeed = props.waveSpeed || 0.001
    const speedIn = props.speedIn || 1
    const speedOut = props.speedOut || 1

    let texture = props.texture || console.error("no texture!")
    let easing = props.easing || "Expo.easeInOut"

    const scene = new Scene()

    const camera = new OrthographicCamera(
      parent.offsetWidth / -2,
      parent.offsetWidth / 2,
      parent.offsetHeight / 2,
      parent.offsetHeight / -2,
      1,
      1000
    )

    camera.position.z = 1

    const renderer = new WebGLRenderer({
      antialias: false,
      canvas: props.canvas,
    })

    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setClearColor(0xffffff, 0.0)
    renderer.setSize(parent.offsetWidth, parent.offsetHeight)

    const loader = new TextureLoader()
    loader.crossOrigin = ""
    texture = loader.load(texture, initAnimation)

    texture.minFilter = LinearFilter
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy()

    const mat = new ShaderMaterial({
      uniforms: {
        time: { type: "f", value: 0 },
        image: { type: "t", value: texture },
        mouseOver: { type: "f", value: mouseOver },
        intensity: { type: "f", value: intensity * playhead },
        strength: { type: "f", value: strength * playhead },
        area: { type: "f", value: area * playhead },
        waveSpeed: { type: "f", value: waveSpeed * playhead },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
    })

    const geometry = new PlaneGeometry(
      parent.offsetWidth,
      parent.offsetHeight,
      1
    )

    const object = new Mesh(geometry, mat)
    scene.add(object)

    function start() {
      gsap.to(mat.uniforms.mouseOver, {
        duration: speedIn,
        value: 1,
        ease: easing,
      })
    }

    startRef.current = start

    function stop() {
      gsap.to(mat.uniforms.mouseOver, {
        duration: speedOut,
        value: 0,
        ease: easing,
      })
    }

    stopRef.current = stop

    const animate = function () {
      time++

      mat.uniforms.time.value = time
      requestAnimationFrame(animate)
      renderer.render(scene, camera)
    }

    function handleResize() {
      renderer.setSize(parent.offsetWidth, parent.offsetHeight)
    }

    window.addEventListener("resize", handleResize)

    if (props.pauseOnHover) {
      parent.addEventListener("mouseenter", stop)
      parent.addEventListener("mouseleave", start)
    }

    function initAnimation() {
      animate()
      startRef.current()
    }

    return () => {
      scene.remove.apply(scene, scene.children)

      //   renderer.renderLists.dispose()
      //   renderer.dispose()
      //   geometry.dispose()
      //   mat.dispose()
      //   texture.dispose()

      // traverse the scene for all disposables
      scene.traverse(function (obj) {
        // dispose geometry
        if (obj.geometry) {
          obj.geometry.dispose()
        }
        // dispose materials
        if (obj.material) {
          // a mesh's material may be an array
          if (Array.isArray(obj.material)) {
            obj.material.forEach(function (mtl) {
              mtl.dispose()
            })
          } else {
            obj.material.dispose()
          }
        }
      })

      // dispose textures, cache them while constructing the scene
      texture.dispose()
      // texture.forEach(function (t) {
      //   t.dispose()
      // })

      // dispose the scene if needed
      //scene.dispose()
      renderer.dispose()

      window.removeEventListener("resize", handleResize)

      if (props.pauseOnHover) {
        parent.removeEventListener("mouseenter", stop)
        parent.removeEventListener("mouseleave", start)
      }
    }
  }, [props.isReady])

  return {
    start: startRef.current,
    stop: stopRef.current,
  }
}
