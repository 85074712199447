import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { resetCursorSize } from "utils/animationUtil"
import { useNav } from "hooks/useNav"
import { useFooter } from "hooks/useFooter"

export default function useAboutUs(isReady) {
  const aboutUsRef = useRef()
  const q = gsap.utils.selector(aboutUsRef)
  const { isNavAnimating } = useNav()
  const { handleShowBackToTop } = useFooter()

  useEffect(() => {
    handleShowBackToTop(true)
    return handleShowBackToTop
  }, [])

  useEffect(() => {
    if (!isReady || isNavAnimating) return

    gsap
      .timeline()
      .to(q(".about-us-title span"), {
        opacity: 1,
        x: 0,
        y: 0,
        stagger: 0.2,
        rotate: 0,
        ease: "back.out(1.5)",
      })
      .to(
        q(".about-us-content p"),
        {
          opacity: 1,
          stagger: 0.4,
          y: 0,
          x: 0,
        },
        0.3
      )
      .to(
        q(".about-images"),
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        0.6
      )
      .to(
        q(".about-first-quote"),
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        0.8
      )
      .to(
        q(".about-circle-image"),
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        0.8
      )
    return () => resetCursorSize()
  }, [isReady, isNavAnimating])

  return {
    aboutUsRef,
  }
}
