import React from "react"
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"

export default function ErrorBoundary({ children }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorPage}>
      {children}
    </ReactErrorBoundary>
  )
}

function ErrorPage() {
  function handleReset() {
    window.location.href = "/"
  }

  return (
    <StyledErrorPage>
      <Container>
        <Row>
          <Col>
            <h1 className="mb-3">Something went wrong</h1>
            <p className="mb-3">An error has occured in the application.</p>
            <StyledButton onClick={handleReset}>Try again</StyledButton>
          </Col>
        </Row>
      </Container>
    </StyledErrorPage>
  )
}

const StyledErrorPage = styled.div`
  font-family: ${vars.primaryFont};
`

const StyledButton = styled.button`
  font-size: 16px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  cursor: pointer;
  line-height: 1;
  border: none;
  color: ${vars.white};
  opacity: 1;
  transition: ease-in-out 200ms;
  background: ${vars.primary};
  border-radius: 0;
  font-family: ${vars.primaryFont};
  font-weight: normal;
  text-transform: uppercase;
  position: relative;
  user-select: none;
  z-index: 10;

  &:hover {
    background: ${vars.primaryDark};
  }
`
