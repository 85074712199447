import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import PageTemplate from "templates/PageTemplate"
import LoadingIcon from "components/common/LoadingIcon"
import useAdvisor from "hooks/useAdvisor"
import { noImage } from "utils/imageUtil"
import vars from "styles/variables"
import Seo from "components/common/Seo"

function AdvisorsPage() {
  const { advisorRef, advisors, isLoading } = useAdvisor()

  if (isLoading) return <LoadingIcon width={60} colour="primary" isFullScreen />

  return (
    <StyledAdvisorsPage ref={advisorRef}>
      <Seo title="Our Advisors" />
      <PageTemplate hideTitle>
        <Container className="pb-3">
          <Row>
            <Col xl="6" className="mb-4">
              <h1 className="advisor-title">
                <span className="rotate rotate-1">o</span>
                <span className="rotate rotate-2">u</span>
                <span className="rotate rotate-3">r </span>
                <span className="slide-in">
                  <span className="fw-200">x</span>advisors
                </span>
              </h1>
            </Col>
            {advisors.map((item, index) => (
              <Col key={index} lg="6" className="mb-4 text-center text-xl-left">
                <Row>
                  <Col xl="auto">
                    <StyledProfileImage
                      src={item.image || noImage}
                      alt={item.name}
                      className="mb-3 animate-advisor"
                    />
                  </Col>
                  <Col className="animate-advisor">
                    <h2 className="mb-3">
                      {item.firstName} {item.surname}
                    </h2>
                    <p className="text-uppercase">{item.jobTitle}</p>
                    <div dangerouslySetInnerHTML={{ __html: item.bio }} />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Container>
      </PageTemplate>
    </StyledAdvisorsPage>
  )
}

export default AdvisorsPage

const StyledAdvisorsPage = styled.div`
  padding-bottom: 60px;

  h1 {
    font-size: 76px;
    letter-spacing: 10px;
    line-height: 1;
    position: relative;
    padding-bottom: 178px;

    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 50px;
      letter-spacing: 6px;
      padding-bottom: 130px;
    }

    span {
      left: 0;
      &.fw-200 {
        font-weight: 200;
        display: inline-block;
        margin-right: 25px;
        transform: scale(1.4, 1.05);
        padding-left: 11px;

        @media (max-width: ${vars.screen_xs_max}) {
          padding-left: 7px;
        }
      }

      &.slide-in {
        opacity: 0;
        transform: translate(40px, 0);
        display: block;
      }

      &.rotate {
        position: absolute;
        line-height: 1;
        opacity: 0;
        transform: translate(40px, 40px);
      }

      &.rotate-1 {
        top: 190px;
        left: -5px;
        @media (max-width: ${vars.screen_xs_max}) {
          top: 131px;
          left: -4px;
        }
      }
      &.rotate-2 {
        top: 130px;
        left: -7px;
        @media (max-width: ${vars.screen_xs_max}) {
          top: 93px;
          left: -5px;
        }
      }
      &.rotate-3 {
        top: 78px;
        left: 2px;
        @media (max-width: ${vars.screen_xs_max}) {
          top: 59px;
          left: 1px;
        }
      }
    }
  }

  .animate-advisor {
    opacity: 0;
    transform: translate(0, 40px);
  }
`

const StyledProfileImage = styled.img`
  border-radius: 50%;
  max-width: 200px;
  width: 100%;
`
