import React, { useLayoutEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { useHistory } from "react-router"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import useWaterRipple from "hooks/useWaterRipple"
import { homeCoreImages, homeBackgroundImage } from "utils/imageUtil"

export default function useHome() {
  const homeRef = useRef()
  const bgCanvasRef = useRef()
  const innerCircleCanvasRef = useRef()
  const middleCircleCanvasRef = useRef()
  const outerCircleCanvasRef = useRef()
  const q = gsap.utils.selector(homeRef)
  const breathTimeline = useRef()
  const history = useHistory()
  const [isBgImageReady, setIsBgImageReady] = useState(false)
  const [isCirclesReady, setIsCirclesReady] = useState(false)
  const [showWaterCircles, setShowWaterCircle] = useState(false)
  const [showWeAre, setShowWeAre] = useState(false)
  const [showLogo, setShowLogo] = useState(false)
  const [showCoreContent, setShowCoreContent] = useState(true)

  const bgImages = useRef([
    homeCoreImages.outerLarge,
    homeCoreImages.middleLarge,
    homeCoreImages.innerLarge,
  ])

  const [bgImage, setBgImage] = useState(homeBackgroundImage)

  // useWaterRipple({
  //   parent: ".home-bg-image",
  //   texture: bgImage,
  //   isReady: isBgImageReady,
  //   canvas: bgCanvasRef?.current,
  // })

  useWaterRipple({
    parent: ".dynamic-circle-1",
    texture: homeCoreImages.innerSmall,
    intensity: 0.8,
    canvas: innerCircleCanvasRef?.current,
    isReady: isCirclesReady,
    pauseOnHover: true,
  })

  useWaterRipple({
    parent: ".dynamic-circle-2",
    texture: homeCoreImages.middleSmall,
    canvas: middleCircleCanvasRef?.current,
    isReady: isCirclesReady,
    pauseOnHover: true,
  })

  useWaterRipple({
    parent: ".dynamic-circle-3",
    texture: homeCoreImages.outerSmall, //? Could also try large version
    canvas: outerCircleCanvasRef?.current,
    isReady: isCirclesReady,
    pauseOnHover: true,
  })

  const [homeContainerClass, setHomeContainerClass] = useState("active")
  const [innerCircleData, setInnerCircleData] = useState(null)

  useLayoutEffect(() => {
    const isHomepageIntroComplete = sessionStorage.getItem(
      "home-intro-complete"
    )

    homepageInit()
    setIsBgImageReady(true)
    //setIsCirclesReady(true)

    function homepageInit() {
      gsap
        .timeline()
        .to(q(".inner-circle"), {
          opacity: 1,
          duration: 1,
          height: "42%",
          onComplete: () => {
            if (!isHomepageIntroComplete) {
              setInnerCircleData({
                title: "We are an international design consultancy",
              })
              setShowCoreContent(false)
            } else {
              setHomeContainerClass(null)
              setInnerCircleData(null)
            }
          },
        })
        .to(q(".core-content"), {
          opacity: 1,
          duration: 1,
          onComplete: () => {
            if (!isHomepageIntroComplete) {
              homepageIntro()
            } else {
              introInnerCircles()
            }
          },
        })
    }

    function homepageIntro() {
      gsap
        .timeline({
          onComplete: () => {
            setShowWeAre(false)
            introInnerCircles()
          },
        })
        .to(q(".core-content"), {
          delay: 4,
          opacity: 0,
          duration: 1,
          onComplete: () => {
            setInnerCircleData({
              title:
                "We blend <strong>data with innovation</strong>, helping global brands realise their creative dreams.",
            })
          },
        })
        .to(q(".core-content"), {
          opacity: 1,
          duration: 1,
        })
        .to(q(".core-content"), {
          delay: 4,
          opacity: 0,
          duration: 1,
          onComplete: () => {
            setShowWeAre(true)
            setShowLogo(true)
            setHomeContainerClass(null)
            setInnerCircleData(null)
            sessionStorage.setItem("home-intro-complete", "true")
          },
        })
        .to(q(".core-content"), {
          opacity: 1,
          duration: 1,
        })
        .to(q(".default-core-content-we-are"), {
          delay: 1,
          opacity: 0,
          duration: 1,
        })
        .to(q(".default-core-logo"), {
          y: -20,
        })
    }

    function introInnerCircles() {
      gsap.to(q(".dynamic-circle"), {
        clipPath: function (index, target) {
          return `circle(${target.dataset.defaultClipPath}%)`
        },
        duration: 2,
        ease: "back.out(1.5)",
        onStart: introOuterCircles,
        delay: isHomepageIntroComplete ? 1 : 0,
      })
    }

    function introOuterCircles() {
      const introEase = "back.out(1.7)"

      gsap
        .timeline({
          onComplete: () => {
            breath()
            setIsCirclesReady(true)
            setShowWaterCircle(true)
            setShowLogo(false)
            setShowCoreContent(true)
          },
        })
        .to(
          q(".circle-thin"),
          {
            height: "130%",
            duration: 1.7,
            ease: introEase,
          },
          0
        )
        .to(
          q(".fade-circle-1"),
          {
            height: "120%",
            duration: 2,
            ease: introEase,
          },
          0
        )
        .to(
          q(".fade-circle-2"),
          {
            height: "180%",
            duration: 1.5,
            ease: introEase,
          },
          0
        )
        .to(q(".default-core-logo"), {
          duration: 1,
          opacity: 0,
        })
    }

    function breath() {
      breathTimeline.current = gsap
        .timeline()
        .to(q(".dynamic-circle"), {
          clipPath: `circle(+=2%)`,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)

      gsap
        .to(q(".circle-breath"), {
          height: `+=20%`,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)

      gsap
        .to(q(".inner-circle"), {
          scale: 1.05,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)
    }

    return () => resetCursorSize()
  }, [])

  function handleCircleEnter(e, circleData) {
    setHomeContainerClass("active")

    enlargeCursorSize()

    if (breathTimeline.current) {
      breathTimeline.current.pause()
    }

    gsap
      .timeline()
      .to(q(".core-content"), {
        opacity: 0,
        duration: 0.3,
        onComplete: () => setInnerCircleData(circleData),
      })
      .to(q(".core-content"), {
        opacity: 1,
        duration: 0.6,
      })

    const index = e.target.getAttribute("data-index")
    const defaultClipPath = Number(
      e.target.getAttribute("data-default-clip-path")
    )

    gsap.to(q(`.dynamic-circle-${index}`), {
      clipPath: `circle(${defaultClipPath + 4}%)`,
    })

    // gsap.to(q(".home-bg-image"), {
    //   opacity: 0.4,
    //   duration: 0.4,
    //   onComplete: updateBackground,
    // })

    // function updateBackground() {
    //   //? We no longer update the background image as we are using the webgl water effect on the background
    //   //setBgImage(bgImages.current[index - 1])
    //   gsap.to(q(".home-bg-image"), { opacity: 1, duration: 0.4 })
    // }
  }

  function handleCircleLeave(e) {
    resetCursorSize()

    const index = e.target.getAttribute("data-index")
    const defaultClipPath = Number(
      e.target.getAttribute("data-default-clip-path")
    )

    setHomeContainerClass("")

    gsap.to(q(`.dynamic-circle-${index}`), {
      clipPath: `circle(${defaultClipPath}%)`,
    })
  }

  function handleCircleClick(url) {
    history.push(url)
  }

  return {
    homeRef,
    bgCanvasRef,
    innerCircleCanvasRef,
    middleCircleCanvasRef,
    outerCircleCanvasRef,
    bgImage,
    homeContainerClass,
    innerCircleData,
    showWaterCircles,
    showWeAre,
    showLogo,
    showCoreContent,
    handleCircleClick,
    handleCircleEnter,
    handleCircleLeave,
  }
}
