import React, { useRef, useEffect } from "react"

// const lerp = (a, b, n) => (1 - n) * a + n * b

// class Cursor {
//   constructor() {
//     // config
//     this.target = { x: 0, y: 0 } // mouse position
//     this.cursor = { x: 0, y: 0 } // cursor position
//     this.speed = 0.2
//     this.init()
//   }
//   bindAll() {
//     ;["onMouseMove", "render"].forEach(fn => (this[fn] = this[fn].bind(this)))
//   }
//   onMouseMove(e) {
//     //get normalized mouse coordinates [0, 1]
//     this.target.x = e.clientX / window.innerWidth
//     this.target.y = e.clientY / window.innerHeight
//     // trigger loop if no loop is active
//     if (!this.raf) this.raf = requestAnimationFrame(this.render)
//   }
//   render() {
//     //calculate lerped values
//     this.cursor.x = lerp(this.cursor.x, this.target.x, this.speed)
//     this.cursor.y = lerp(this.cursor.y, this.target.y, this.speed)
//     document.documentElement.style.setProperty("--cursor-x", this.cursor.x)
//     document.documentElement.style.setProperty("--cursor-y", this.cursor.y)
//     //cancel loop if mouse stops moving
//     const delta = Math.sqrt(
//       Math.pow(this.target.x - this.cursor.x, 2) +
//         Math.pow(this.target.y - this.cursor.y, 2)
//     )
//     if (delta < 0.001) {
//       cancelAnimationFrame(this.raf)
//       this.raf = null
//       return
//     }
//     //or continue looping if mouse is moving
//     this.raf = requestAnimationFrame(this.render)
//   }
//   init() {
//     this.bindAll()
//     window.addEventListener("mousemove", this.onMouseMove)
//     this.raf = requestAnimationFrame(this.render)
//   }
// }

function CustomCursor() {
  const cursorRef = useRef()

  useEffect(() => {
    if (window.navigator.userAgentData?.mobile) return

    document.addEventListener("mousemove", event => {
      const { clientX, clientY } = event
      const mouseX = clientX - cursorRef.current.clientWidth / 2
      const mouseY = clientY - cursorRef.current.clientHeight / 2

      //cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`
      //cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`

      cursorRef.current.style.left = mouseX + "px"
      cursorRef.current.style.top = mouseY + "px"
    })
  }, [])

  // useEffect(() => {
  //   new Cursor()
  // }, [])

  return (
    <div id="cursor" className="app-cursor" ref={cursorRef}>
      <div className="app-cursor-inner"></div>
    </div>
  )
}

export default CustomCursor
