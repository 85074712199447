import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import ProjectBackgroundCircle from "components/core/ProjectBackgroundCircle"

function TextWithFeature({ data }) {
  const {
    title,
    subTitle,
    htmlContent,
    bannerImage1,
    bannerImage2,
    bannerImage3,
    overlayText,
    paddingActive,
    overlayTextPositionTop,
    backgroundCirclePositionTop,
    backgroundCircleColour,
  } = data.content

  return (
    <StyledTextWithFeature>
      <ProjectBackgroundCircle
        colour={backgroundCircleColour}
        top={backgroundCirclePositionTop ? "unset" : "10%"}
        bottom={backgroundCirclePositionTop ? "10%" : "unset"}
        right="0"
        left="unset"
      />
      <Container fluid={true}>
        <Row>
          <Col lg="4" className="gsap-slide-down-1">
            {title && <h2>{title}</h2>}
            {subTitle && <h3>{subTitle}</h3>}
            {htmlContent && (
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}
          </Col>
          <Col lg="6" className="offset-2">
            {bannerImage1 && (
              <img
                src={bannerImage1}
                alt=""
                className={`banner-1  ${paddingActive ? "with-padding" : ""}`}
              />
            )}
            {bannerImage2 && (
              <img
                src={bannerImage2}
                alt=""
                className="banner-2 gsap-slide-left-2"
              />
            )}
            {bannerImage3 && (
              <img
                src={bannerImage3}
                alt=""
                className="banner-3 gsap-slide-left-1"
              />
            )}
            {overlayText && (
              <span
                className={`overlay-text gsap-slide-left-1 ${
                  overlayTextPositionTop ? "position-top" : ""
                }`}
              >
                {overlayText}
              </span>
            )}
          </Col>
        </Row>
      </Container>
    </StyledTextWithFeature>
  )
}

export default TextWithFeature

const StyledTextWithFeature = styled.div`
  padding: 0 5vw;

  .container-fluid {
    z-index: 50;
    position: relative;
  }

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
  }

  h3 {
    font-family: ${vars.secondaryFont};
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
  }

  .overlay-text {
    position: absolute;
    bottom: 100px;
    left: 40%;
    font-weight: 600;
    font-size: 89px;
    line-height: 84px;
    mix-blend-mode: difference;
    color: ${vars.grey_400};

    &.position-top {
      bottom: unset;
      top: 100px;
      left: 100%;
    }
  }

  .banner-1 {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    &.with-padding {
      height: 90vh;
      margin-top: 5vh;
    }
  }

  .banner-2 {
    position: absolute;
    top: 10%;
    left: 20%;
  }

  .banner-3 {
    position: absolute;
    bottom: 10%;
    right: -20%;
  }
`
