import React from "react"
import { Container, Col, Row } from "reactstrap"
import PageTemplate from "templates/PageTemplate"

function NotFoundPage() {
  return (
    <PageTemplate>
      <Container fluid>
        <Row>
          <Col>
            <h1>404: Page Not Found</h1>
            <p>We can not find the page you are looking for.</p>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  )
}

export default NotFoundPage
