import React from "react"
import styled from "styled-components"
import vars from "styles/variables"

function PageTemplate({ title, children, hideTitle, ...rest }) {
  return (
    <div {...rest}>
      <StyledPageTemplateContent data-scroll-section hideTitle={hideTitle}>
        {title && (
          <h1 className={`mb-0 ${hideTitle ? "d-none " : ""}`}>{title}</h1>
        )}
      </StyledPageTemplateContent>
      {children}
    </div>
  )
}

export default PageTemplate

const StyledPageTemplateContent = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media (max-width: ${vars.screen_lg_max}) {
    margin-top: ${props => (props.hideTitle ? "0" : "80px")};
    margin-bottom: 10px;
  }
`
