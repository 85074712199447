import React from "react"
import { Col, Container, Row } from "reactstrap"
import PageTemplate from "templates/PageTemplate"

function CookiePolicyPage() {
  return (
    <PageTemplate title="Cookie Policy">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" xl="8" className="mb-4">
            <h2>About this cookie policy</h2>
            <p>
              This Cookie Policy explains what cookies are and how we use them,
              the types of cookies we use i.e, the information we collect using
              cookies and how that information is used, and how to control the
              cookie preferences.
            </p>
            <p>Your consent applies to the following domains: io-atelier.com</p>
            <p>Your current state: Consent accepted. </p>
            <h2>What are cookies?</h2>
            <p>
              Cookies are small text files that are used to store small pieces
              of information. They are stored on your device when the website is
              loaded on your browser. These cookies help us make the website
              function properly, make it more secure, provide better user
              experience, and understand how the website performs and to analyse
              what works and where it needs improvement.
            </p>
            <h2>How do we use cookies?</h2>
            <p>
              As most of the online services, our website uses first-party and
              third-party cookies for several purposes. First-party cookies are
              mostly necessary for the website to function the right way, and
              they do not collect any of your personally identifiable data.{" "}
            </p>
            <p>
              The third-party cookies used on our website are mainly for
              understanding how the website performs, how you interact with our
              website, keeping our services secure, providing advertisements
              that are relevant to you, and all in all providing you with a
              better and improved user experience and help speed up your future
              interactions with our website.
            </p>
            <h2>What types of cookies do we use?</h2>
            <p>
              Essential: Some cookies are essential for you to be able to
              experience the full functionality of our site. They allow us to
              maintain user sessions and prevent any security threats. They do
              not collect or store any personal information. For example, these
              cookies allow you to log-in to your account and add products to
              your basket, and checkout securely. Statistics: These cookies
              store information like the number of visitors to the website, the
              number of unique visitors, which pages of the website have been
              visited, the source of the visit, etc. These data help us
              understand and analyze how well the website performs and where it
              needs improvement.
            </p>
            <p>
              Marketing: Our website displays advertisements. These cookies are
              used to personalize the advertisements that we show to you so that
              they are meaningful to you. These cookies also help us keep track
              of the efficiency of these ad campaigns. The information stored in
              these cookies may also be used by the third-party ad providers to
              show you ads on other websites on the browser as well.
            </p>
            <p>
              Functional: These are the cookies that help certain non-essential
              functionalities on our website. These functionalities include
              embedding content like videos or sharing content of the website on
              social media platforms. Preferences: These cookies help us store
              your settings and browsing preferences like language preferences
              so that you have a better and efficient experience on future
              visits to the website.
            </p>
            <h2>How can I control the cookie preferences?</h2>
            <p>
              Different browsers provide different methods to block and delete
              cookies used by websites. You can change the settings of your
              browser to block/delete the cookies. To find out more about how to
              manage and delete cookies, visit:{" "}
              <a href="https://allaboutcookies.org/" target="_blank">
                allaboutcookies.org
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  )
}

export default CookiePolicyPage
