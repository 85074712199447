import { useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import LocomotiveScroll from "locomotive-scroll"
import "locomotive-scroll/dist/locomotive-scroll.css"
import { useNav } from "hooks/useNav"

gsap.registerPlugin(ScrollTrigger)

export default function useLocoScroll(start) {
  const locoScroll = useRef()
  const { isNavOpen } = useNav()

  useEffect(() => {
    if (!start || !locoScroll.current) return

    if (isNavOpen) locoScroll.current.stop()
    else locoScroll.current.start()
  }, [isNavOpen, start])

  useEffect(() => {
    if (!start) return

    const scrollEl = document.querySelector("#main-container")

    locoScroll.current = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      multiplier: 1,
      class: "is-reveal",
    })

    locoScroll.current.on("scroll", () => {
      ScrollTrigger.update()
    })

    ScrollTrigger.scrollerProxy(scrollEl, {
      scrollTop(value) {
        if (locoScroll.current) {
          return arguments.length
            ? locoScroll.current.scrollTo(value, 0, 0)
            : locoScroll.current.scroll.instance.scroll.y
        }
        return null
      },
      scrollLeft(value) {
        if (locoScroll.current) {
          return arguments.length
            ? locoScroll.current.scrollTo(value, 0, 0)
            : locoScroll.current.scroll.instance.scroll.x
        }
        return null
      },
    })

    const lsUpdate = () => {
      if (locoScroll.current) {
        locoScroll.current.update()
      }
    }

    ScrollTrigger.addEventListener("refresh", lsUpdate)
    ScrollTrigger.refresh()

    return () => {
      if (locoScroll.current) {
        ScrollTrigger.removeEventListener("refresh", lsUpdate)
        locoScroll.current.destroy()
        locoScroll.current = null
      }
    }
  }, [start])

  function locoScrollTop() {
    if (locoScroll?.current)
      locoScroll.current.scroll.scrollTo("top", {
        offset: 0,
        duration: 600,
        easing: [0.25, 0.0, 0.35, 1.0],
        disableLerp: true,
      })
  }

  return {
    locoScrollTop,
    locoScroll: locoScroll.current,
  }
}
