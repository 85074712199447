import * as React from "react"

const ArrowCurvedRight = props => (
  <svg
    width={90}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#1D1D1B">
      <path d="M89.322 17.462c-2.309-4.291-4.617-8.605-6.994-12.896-.815-1.461-1.766-2.854-2.739-4.223-.181-.274-.747-.251-1.154-.365-.091.388-.34.821-.272 1.164.158.639.453 1.255.77 1.826 2.625 4.793 5.296 9.587 7.922 14.403.249.457.453.913.792 1.575-.566-.091-.928-.137-1.29-.205l-19.965-4.11c-.61-.136-1.267-.227-1.403.549-.023.205.634.707 1.064.799 6.926 1.62 13.853 3.195 20.78 4.77.995.229 2.037.57 2.784-.456.747-1.005.158-1.94-.317-2.83" />
      <path d="M75.851 11.8c.543.252 1.177.366 1.381-.319.09-.274-.34-.867-.656-1.05a50.87 50.87 0 0 0-4.052-1.94C62.632 4.223 52.31 1.826 41.58 1.255 30.036.662 18.809 2.168 7.876 5.957 5.363 6.825 2.94 7.83.565 8.925H.542c-.408.228-.634.639-.52 1.05.135.48.633.684 1.176.525 9.168-4.246 18.675-6.597 28.77-7.373 13.786-1.073 27.14.89 39.975 6.049 1.992.799 3.939 1.735 5.908 2.625Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h90v21H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowCurvedRight
