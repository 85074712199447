import React from "react"
import styled from "styled-components"
import MainNav from "components/core/MainNav"

function Header() {
  return (
    <StyledHeader data-scroll-section>
      <MainNav />
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 290;
  height: 100px;
  display: flex;
  align-items: center;
`
