import vars from "styles/variables"

export const muiTheme = {
  palette: {
    primary: {
      main: vars.adminPrimary,
    },
    secondary: {
      main: vars.adminSecondary,
    },
    white: {
      main: vars.white,
    },
  },
  overrides: {
    MuiButton: {
      text: {
        color: "white",
      },
      containedPrimary: {
        color: "white",
      },
      outlinedPrimary: {
        border: `2px solid ${vars.adminPrimary}`,
        "&:hover": {
          border: `2px solid ${vars.adminPrimary}`,
        },
      },
      outlinedSecondary: {
        border: `2px solid ${vars.adminSecondary}`,
        "&:hover": {
          border: `2px solid ${vars.adminSecondary}`,
        },
      },
    },
  },
}
