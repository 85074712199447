import React from "react"
import ArrowUpIcon from "components/svg/ArrowUpIcon"
import styled from "styled-components"
import vars from "styles/variables"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"

function BackToTop(props) {
  return (
    <StyledBackToTopButton
      onMouseEnter={enlargeCursorSize}
      onMouseLeave={resetCursorSize}
      {...props}
    >
      Back To Top <ArrowUpIcon />
    </StyledBackToTopButton>
  )
}

export default BackToTop

const StyledBackToTopButton = styled.div`
  position: fixed;
  background: ${vars.grey_600};
  color: ${vars.white};
  padding: 10px 20px;
  border-radius: 56px;
  text-transform: uppercase;
  z-index: 240;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 1rem;
  bottom: 20px;
  left: 20px;
  line-height: 1;
  font-size: 14px;
`
