import { gsap } from "gsap"

export function parallaxIt(
  e,
  target,
  movement,
  ease = "Power2.easeOut",
  duration = 1
) {
  const $this = document.querySelector("#root")

  const relX = e.pageX - $this.offsetLeft
  const relY = e.pageY - $this.offsetTop

  gsap.to(target, {
    x: ((relX - $this.offsetWidth / 2) / $this.offsetWidth) * movement,
    y: ((relY - $this.offsetHeight / 2) / $this.offsetHeight) * movement,
    ease,
    duration,
  })
}

export function attachIt(e, target, container) {
  const $this = document.querySelector(container)
  const width = $this.offsetWidth
  const height = $this.offsetHeight

  const relX = e.pageX - $this.offsetLeft
  const relY = e.pageY - $this.offsetTop

  gsap.to(target, {
    x: ((relX - width / 2) / width) * width,
    y: ((relY - height / 2) / height) * height,
    ease: "Power2.easeOut",
    duration: 0.3,
  })
}

export function enlargeCursorSize() {
  document.body.classList.add("is-cursor-large")
}

export function resetCursorSize() {
  document.body.classList.remove("is-cursor-large")
}

export function createScrollTriggerAnimation(
  scroller,
  elmClassName,
  animationProps = {},
  scrollTriggerProps = {}
) {
  const items = gsap.utils.toArray(elmClassName)

  items.forEach(text => {
    gsap.to(text, {
      ease: "none",
      ...animationProps,
      scrollTrigger: {
        containerAnimation: scroller,
        trigger: text,
        scrub: true,
        ...scrollTriggerProps,
      },
    })
  })
}
