import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { resetCursorSize } from "utils/animationUtil"
import { useNav } from "hooks/useNav"
import { useFooter } from "hooks/useFooter"

export default function useDataCreativity(isReady) {
  const dataCreativityRef = useRef()
  const q = gsap.utils.selector(dataCreativityRef)
  const { isNavAnimating } = useNav()
  const { handleShowBackToTop } = useFooter()

  useEffect(() => {
    handleShowBackToTop(true)
    return handleShowBackToTop
  }, [])

  useEffect(() => {
    if (!isReady || isNavAnimating) return

    gsap
      .timeline()
      .to(q(".data-creativity-title-1"), {
        opacity: 1,
        x: 0,
        duration: 1.6,
      })
      .to(
        q(".data-creativity-title-2"),
        {
          opacity: 1,
          x: 0,
          duration: 1.6,
        },
        0.5
      )
      .to(
        q(".fade-in-slide"),
        {
          opacity: 1,
          x: 0,
          duration: 1,
        },
        0.5
      )
      .to(
        q(".data-creativity-banner"),
        {
          opacity: 1,
          duration: 2,
        },
        0.3
      )

    return () => resetCursorSize()
  }, [isReady, isNavAnimating])

  return {
    dataCreativityRef,
  }
}
