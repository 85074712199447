import * as React from "react"

const CircleHandHeartIcon = props => (
  <svg
    width={115}
    height={115}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#CircleHandHeartIcon)">
      <path
        d="M57.5 115C25.798 115 0 89.202 0 57.5S25.798 0 57.5 0 115 25.798 115 57.5 89.202 115 57.5 115Zm0-109.565C28.793 5.449 5.45 28.793 5.45 57.5c0 28.707 23.357 52.051 52.05 52.051 28.693 0 52.051-23.358 52.051-52.051S86.207 5.45 57.5 5.45v-.015Z"
        fill="#A8D7B0"
      />
      <path
        d="M49.95 35.008a5.66 5.66 0 0 1 4.044-1.717c1.107 0 2.498.78 4.03 2.27.256.256.596.398.951.398s.695-.142.95-.398a6.655 6.655 0 0 1 4.627-1.972c1.291 0 2.54.51 3.434 1.433a6.036 6.036 0 0 1 1.675 4.186 6.062 6.062 0 0 1-1.675 4.187l-9.04 8.713-9.01-8.7a5.987 5.987 0 0 1-1.689-4.185 6.05 6.05 0 0 1 1.689-4.187m7.55 19.2a2.11 2.11 0 0 0 1.46.596 2.06 2.06 0 0 0 1.462-.596l9.366-9.04a8.6 8.6 0 0 0 2.412-5.96 8.6 8.6 0 0 0-2.412-5.96 7.332 7.332 0 0 0-5.236-2.199 9.203 9.203 0 0 0-5.591 1.958c-1.732-1.475-3.392-2.227-4.953-2.227a8.134 8.134 0 0 0-5.86 2.483 8.622 8.622 0 0 0-2.427 5.974 8.56 8.56 0 0 0 2.427 5.974l9.351 9.011v-.014Z"
        fill="#6FC282"
      />
      <path
        d="M58.947 55.343c-.695 0-1.348-.27-1.845-.752l-9.351-9.011a9.104 9.104 0 0 1-2.583-6.371c0-2.385.908-4.64 2.569-6.358a8.73 8.73 0 0 1 6.23-2.64h.013c1.59 0 3.25.696 4.967 2.087a9.892 9.892 0 0 1 5.563-1.817c2.1 0 4.157.866 5.62 2.37a9.134 9.134 0 0 1 2.553 6.343c0 2.37-.908 4.626-2.554 6.343l-9.38 9.054a2.633 2.633 0 0 1-1.845.752h.043Zm-4.98-24.039a7.59 7.59 0 0 0-5.45 2.313 8.02 8.02 0 0 0-2.27 5.606c0 2.1.808 4.087 2.27 5.605l9.337 8.997c.582.567 1.59.567 2.171 0l9.366-9.04a8.025 8.025 0 0 0 2.242-5.576c0-2.1-.794-4.073-2.256-5.592a6.796 6.796 0 0 0-4.839-2.029c-1.901 0-3.76.667-5.25 1.845l-.355.284-.34-.284c-1.633-1.39-3.18-2.1-4.599-2.1h-.014l-.014-.029Zm4.98 21.556-9.38-9.068a6.546 6.546 0 0 1-1.859-4.584c0-1.717.653-3.334 1.845-4.569a6.184 6.184 0 0 1 4.427-1.873c1.263 0 2.753.809 4.414 2.427a.792.792 0 0 0 1.135 0 7.217 7.217 0 0 1 5.009-2.143h.043c1.433 0 2.81.582 3.803 1.603a6.576 6.576 0 0 1 1.83 4.556c0 1.702-.653 3.32-1.816 4.555L58.96 52.86h-.014ZM50.333 35.39a5.486 5.486 0 0 0 0 7.635l8.614 8.33 8.67-8.359a5.479 5.479 0 0 0 1.505-3.788c0-1.42-.54-2.782-1.519-3.804a4.208 4.208 0 0 0-3.008-1.263h-.029a6.073 6.073 0 0 0-4.243 1.817c-.71.723-1.972.71-2.682 0-1.39-1.348-2.696-2.1-3.646-2.1a5.125 5.125 0 0 0-3.648 1.546l-.014-.014Z"
        fill="#6FC282"
      />
      <path
        d="M76.997 57.982 58.89 76.09a2.18 2.18 0 0 1-1.547.638H40.358c-.34 0-.652.128-.894.369l-4.072 4.073-7.961-7.961 9.806-9.792a4.963 4.963 0 0 1 3.434-1.39h21.654c.653 0 1.263.298 1.675.809.014.028.042.042.07.07a2.18 2.18 0 0 1-1.745 3.49H50.376c-.695.001-1.263.569-1.263 1.264 0 .696.568 1.263 1.263 1.263h11.948c1.263 0 2.441-.496 3.335-1.376a4.667 4.667 0 0 0 1.377-3.335c0-.695-.17-1.362-.454-1.973l7.336-7.336c.823-.823 2.256-.823 3.08 0 .41.412.638.965.638 1.547s-.227 1.12-.639 1.547v-.015Zm1.788-4.881a4.667 4.667 0 0 0-3.334-1.377c-1.263 0-2.441.497-3.335 1.377l-7.18 7.18a4.587 4.587 0 0 0-1.221-.582c-.014 0-.043 0-.057-.014a3.905 3.905 0 0 0-.553-.128c-.043 0-.085-.014-.142-.028a4.822 4.822 0 0 0-.639-.042H40.67a7.488 7.488 0 0 0-5.208 2.114L24.748 72.315a1.268 1.268 0 0 0 0 1.788l9.763 9.763c.241.241.553.369.894.369.34 0 .653-.128.894-.37l4.598-4.597h16.46c1.264 0 2.441-.496 3.336-1.376L78.8 59.785a4.667 4.667 0 0 0 1.376-3.335c0-1.263-.497-2.441-1.376-3.335"
        fill="#6FC282"
      />
      <path
        d="M35.378 84.774c-.483 0-.937-.184-1.277-.525l-9.764-9.763a1.816 1.816 0 0 1 0-2.554l10.714-10.714a8 8 0 0 1 5.591-2.27h21.655c.241 0 .483.014.724.056l.156.029c.199.028.412.085.61.141.383.114.738.256 1.05.44l6.883-6.882c1.986-1.987 5.449-1.987 7.436 0a5.229 5.229 0 0 1 1.546 3.718 5.229 5.229 0 0 1-1.546 3.718L61.047 78.275a5.229 5.229 0 0 1-3.718 1.547H41.097l-4.442 4.441c-.34.34-.795.525-1.277.525v-.014ZM62.31 60.026H40.657c-1.802 0-3.52.695-4.84 1.958l-10.713 10.7a.697.697 0 0 0-.213.51c0 .185.07.384.213.511l9.763 9.764c.27.27.752.27 1.022 0l4.753-4.754H57.33c1.122 0 2.157-.44 2.952-1.22L78.39 59.386a4.095 4.095 0 0 0 1.22-2.951c0-1.121-.44-2.172-1.22-2.952-1.575-1.575-4.328-1.575-5.903 0l-7.493 7.507-.369-.241a4.733 4.733 0 0 0-1.078-.526c-.213-.056-.369-.099-.54-.127a4.825 4.825 0 0 0-.695-.057v-.014Zm-26.933 21.91-8.727-8.727 10.188-10.175a5.449 5.449 0 0 1 3.818-1.547H62.31a2.72 2.72 0 0 1 2.1 1.008c.44.553.625 1.121.625 1.717 0 .724-.284 1.405-.795 1.93a2.752 2.752 0 0 1-1.93.795H50.363a.727.727 0 0 0-.724.723c0 .398.327.724.724.724h11.948c1.121 0 2.172-.44 2.952-1.22a4.12 4.12 0 0 0 1.22-2.952c0-.582-.142-1.178-.397-1.745l-.156-.34 7.592-7.593a2.725 2.725 0 0 1 1.93-.78 2.75 2.75 0 0 1 1.93.78c.51.511.794 1.192.794 1.93s-.283 1.405-.794 1.93L59.275 76.501c-.511.51-1.192.795-1.93.795H40.359a.675.675 0 0 0-.511.213l-4.456 4.455-.014-.028Zm-7.195-8.727 7.195 7.194 3.69-3.69c.34-.34.794-.524 1.276-.524H57.33c.44 0 .852-.17 1.164-.483l18.107-18.12a1.623 1.623 0 0 0 0-2.314c-.61-.61-1.703-.624-2.313 0l-7.067 7.067a5.01 5.01 0 0 1 .355 1.845 5.229 5.229 0 0 1-1.547 3.718 5.228 5.228 0 0 1-3.718 1.546H50.363a1.82 1.82 0 0 1-1.816-1.816 1.81 1.81 0 0 1 1.816-1.816h11.948c.44 0 .852-.17 1.164-.483.312-.312.483-.724.483-1.163 0-.355-.114-.696-.327-.98-.369-.44-.823-.667-1.305-.667H40.67c-1.15 0-2.228.44-3.065 1.25l-9.409 9.393-.014.043Z"
        fill="#6FC282"
      />
    </g>
    <defs>
      <clipPath id="CircleHandHeartIcon">
        <path fill="#fff" d="M0 0h115v115H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default CircleHandHeartIcon
