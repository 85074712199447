import React, { useEffect, useRef, useState } from "react"
import { gsap } from "gsap"
import { useNav } from "hooks/useNav"
import useFetch from "hooks/useFetch"
import { getAdvisorsList } from "services/teamMembersService"
import useImagePreloader from "hooks/useImagePreloader"

export default function useTeam() {
  const advisorRef = useRef()
  const q = gsap.utils.selector(advisorRef)
  const { isNavAnimating } = useNav()
  const { data, isLoading } = useFetch(getAdvisorsList)

  const [preLoadImageList, setPreLoadImageList] = useState(null)
  const { imagesPreloaded } = useImagePreloader(preLoadImageList)

  useEffect(() => {
    if (isLoading) return

    setPreLoadImageList(data.items.map(x => x.image))
  }, [isLoading])

  useEffect(() => {
    if (!imagesPreloaded || isNavAnimating) return

    gsap
      .timeline()
      .to(q(".advisor-title span.rotate"), {
        opacity: 1,
        x: 0,
        y: 0,
        stagger: 0.2,
        rotate: -90,
        ease: "back.out(1.5)",
      })
      .to(q(".advisor-title .slide-in"), {
        opacity: 1,
        x: 0,
        ease: "back.out(1.5)",
      })
      .to(q(".animate-advisor"), {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        ease: "back.out(1.5)",
        duration: 1,
      })
  }, [imagesPreloaded, isNavAnimating])

  return {
    advisorRef,
    isLoading,
    advisors: data?.items || [],
  }
}
