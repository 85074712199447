import * as React from "react"
import styled from "styled-components"
import vars from "styles/variables"

function InstagramIcon(props) {
  return (
    <svg
      width={27}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={13.5} cy={13.5} r={13.5} fill="#fff" />
      <g clipPath="url(#InstagramIcon)" fill="#242424">
        <path d="M13.503 9.649a3.848 3.848 0 1 0 0 7.696 3.848 3.848 0 0 0 0-7.696Zm0 6.347a2.497 2.497 0 1 1 0-4.997 2.498 2.498 0 1 1 0 4.997Z" />
        <path d="M16.593 6.048c-1.38-.065-4.799-.062-6.18 0-1.213.056-2.284.35-3.147 1.213-1.443 1.442-1.258 3.386-1.258 6.237 0 2.917-.163 4.816 1.258 6.237 1.448 1.447 3.42 1.258 6.237 1.258 2.89 0 3.887.002 4.909-.394 1.39-.54 2.438-1.781 2.54-4.012.066-1.38.062-4.798 0-6.18-.123-2.633-1.536-4.23-4.36-4.36Zm2.184 12.732c-.946.946-2.258.861-5.293.861-3.124 0-4.378.047-5.292-.87-1.053-1.048-.862-2.732-.862-5.283 0-3.454-.355-5.94 3.11-6.118.797-.028 1.031-.037 3.036-.037l.028.018c3.33 0 5.944-.348 6.1 3.117.036.79.044 1.028.044 3.029 0 3.089.058 4.35-.871 5.283Z" />
        <path d="M17.503 10.396a.9.9 0 1 0 0-1.799.9.9 0 0 0 0 1.8Z" />
      </g>
      <defs>
        <clipPath id="InstagramIcon">
          <path fill="#fff" transform="translate(6 6)" d="M0 0h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default InstagramIcon

const StyledIcon = styled.svg`
  path {
    transition: ease-in-out 200ms;
  }

  &:hover {
    .stoke {
      stroke: ${props => props.hoverColor};
    }
    .fill {
      fill: ${props => props.hoverColor};
    }
  }
`
