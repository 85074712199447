import React from "react"

function NavOverlay() {
  return (
    <svg
      viewBox="0 0 200 200"
      preserveAspectRatio="xMidYMid slice"
      width="100%"
      height="100%"
    >
      <defs>
        <mask id="mask" x="0" y="0" width="80" height="30">
          <rect x="0" y="0" width="100%" height="100vh" fill="#fff" />
          <circle cx="50%" cy="50%" r="100%" className="nav-circle-overlay" />
        </mask>
      </defs>
      <rect
        x="0"
        y="0"
        width="100vw"
        height="100vh"
        mask="url(#mask)"
        fill="#fcfcfc"
      />
    </svg>
  )
}

export default NavOverlay
