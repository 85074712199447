import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import { gsap } from "gsap"
import { useHistory } from "react-router"
import { getProjectList } from "services/projectsService"
import LoadingIcon from "components/common/LoadingIcon"
import useFetch from "hooks/useFetch"
import { parallaxIt } from "utils/animationUtil"
import vars from "styles/variables"
import useLocoScroll from "hooks/useLocoScroll"

function ProjectsPage() {
  const projectsContainer = useRef()
  const q = gsap.utils.selector(projectsContainer)
  const history = useHistory()
  const { data, isLoading } = useFetch(getProjectList)
  const [isProjectHover, setIsProjectHover] = useState(false)
  const { locoScrollTop } = useLocoScroll(true)

  useLayoutEffect(() => {
    locoScrollTop()

    gsap.from(q(".project-item"), {
      opacity: 0,
      yPercent: 100,
      duration: 1,
      stagger: 0.2,
      ease: "back.out(1.7)",
    })

    function onMove(event) {
      parallaxIt(event, q(".project-item"), 100)
    }

    document.addEventListener("mousemove", onMove)

    return () => {
      window.removeEventListener("mousemove", onMove)
    }
  }, [isLoading])

  if (isLoading) return <LoadingIcon isFullScreen />

  return (
    <StyledProjectContainer
      className={`${isProjectHover ? "active" : ""}`}
      ref={projectsContainer}
    >
      <Container>
        <Row className="justify-content-center">
          {data.items.map(item => (
            <Col key={item.slug} xs={12} sm={6} lg={4} className="mb-4">
              <StyledProjectScaleUp className="project-scale-up">
                <StyledProjectItem
                  className="project-item"
                  bgImage={item.listImageOuter}
                  onMouseEnter={() => setIsProjectHover(true)}
                  onMouseLeave={() => setIsProjectHover(false)}
                  onClick={() => history.push(`/projects/${item.slug}`)}
                >
                  <StyledProjectItemContent
                    bgImage={item.listImageInner}
                    className="project-content"
                  >
                    {item.title}
                  </StyledProjectItemContent>
                </StyledProjectItem>
              </StyledProjectScaleUp>
            </Col>
          ))}
        </Row>
      </Container>
    </StyledProjectContainer>
  )
}

export default ProjectsPage

const StyledProjectScaleUp = styled.div`
  transition: ease-in-out 500ms;
  &:hover {
    transform: scale(1.1) !important;
  }
`

const StyledProjectContainer = styled.div`
  padding-top: 100px;

  &.active:hover {
    .project-scale-up {
      transform: scale(0.95);
    }
  }
`

const StyledProjectItem = styled.div`
  width: 336px;
  height: 336px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  background: url(${props => props.bgImage}) no-repeat center center;
  opacity: 1;
  background-size: cover;

  &:hover {
    .project-content {
      clip-path: circle(50%);
    }
  }
`

const StyledProjectItemContent = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.bgImage}) no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: circle(35%);
  z-index: 10;
  color: ${vars.white};
  font-size: 24px;
  padding: 20%;
  text-align: center;
  background-size: cover;
  transition: ease-in-out 500ms;
`
