import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import PageTemplate from "templates/PageTemplate"
import useLocoScroll from "hooks/useLocoScroll"
import useImagePreloader from "hooks/useImagePreloader"
import Seo from "components/common/Seo"
import useDataCreativity from "hooks/useDataCreativity"
import ImgBanner from "assets/images/data-creativity-banner.png"
import ImgDominoes from "assets/images/dominoes.png"
import ImgHumanTruth from "assets/images/human-x-truth.png"
import ImgPlaceTruth from "assets/images/place-x-truth.png"
import ImgGetDataInfographic from "assets/images/where-we-get-data-infographic.png"
import WhyChooseUs from "components/core/WhyChooseUs"
import DataCreativityCarousel from "components/core/DataCreativityCarousel"
import DataCreativitySlider from "components/core/DataCreativitySlider"
import FadeCircleSmall from "components/svg/FadeCircleSmall"
import BackToTop from "components/common/BackToTop"

const preloadSrcList = [
  ImgBanner,
  ImgDominoes,
  ImgHumanTruth,
  ImgPlaceTruth,
  ImgGetDataInfographic,
]

function DataCreativityPage() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const { dataCreativityRef } = useDataCreativity(imagesPreloaded)
  const { locoScrollTop } = useLocoScroll(imagesPreloaded)

  if (!imagesPreloaded) return null

  return (
    <StyledDataCreativityPage ref={dataCreativityRef}>
      <Seo title="Data X Creativity" />
      <BackToTop onClick={locoScrollTop} />
      <PageTemplate title="Data X Creativity" hideTitle>
        <Container>
          <Row className="mb-5" data-scroll-section>
            <Col xs="12">
              <StyledTitle
                data-scroll
                data-scroll-speed="2"
                data-scroll-direction="horizontal"
              >
                <span className="data-creativity-title-1">data x</span>
                <span className="data-creativity-title-2">creativity</span>
              </StyledTitle>
            </Col>
          </Row>

          <Row className="mb-5 align-items-center" data-scroll-section>
            <Col xs="12" md="6" className="data-creativity-banner">
              <img
                src={ImgBanner}
                alt=""
                className="img-fluid reveal-up mb-4 mb-md-0"
                data-scroll
                data-scroll-speed="2"
                data-scroll-repeat={true}
              />
            </Col>
            <Col xs="12" md="6" lg="5" className="offset-lg-1 fade-in-slide">
              <StyledQuote data-scroll data-scroll-speed="3">
                &#8220;Without data, you&#8217;re just another person with an
                opinion&#8221;
              </StyledQuote>
              <p data-scroll data-scroll-speed="2">
                W Edwards Deming
                <br />
                Data Scientist
              </p>
            </Col>
          </Row>

          <Row className="mb-5" data-scroll-section>
            <Col xs="12" md="6" lg="4" className="fade-in-slide">
              <h4>Data driven decisions</h4>
              <p>
                &#8220;The art and science of choice is to find out what the
                customer values.&#8221;
              </p>
            </Col>
          </Row>

          <Row className="mb-5 justify-content-center" data-scroll-section>
            <Col xs="12" lg="8">
              <DataCreativityCarousel />
            </Col>
          </Row>

          <Row className="mb-5" data-scroll-section>
            <Col>
              <StyledCircle data-scroll data-scroll-speed="2" />
              <StyledContentOverlay>
                <Row>
                  <Col xs="12" md="6">
                    <h3>What we do?</h3>
                    <p className="mb-5">
                      We look at geo-spatial data that are relevant to the
                      building occupiers and focusing on datasets that we
                      believe are important to consider before we start the
                      design process.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <div className="d-flex h-100 flex-column justify-content-between">
                      <div>
                        <h4>HUMAN x TRUTH</h4>
                        <p className="mb-4">
                          Profiling Employees , Guests and Occupants
                        </p>
                      </div>
                      <div className="img-human-truth">
                        <img src={ImgHumanTruth} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" md="6" lg="5" className="mb-4 offset-lg-1">
                    <h4>PLACE x TRUTH</h4>
                    <p>
                      Data within 500m radius of current and proposed location
                    </p>
                    <img src={ImgPlaceTruth} alt="" className="img-fluid" />
                  </Col>
                  <Col xs="12" className="mt-5">
                    <h3
                      data-scroll
                      data-scroll-speed="-2"
                      data-scroll-direction="horizontal"
                    >
                      Where do we get the data?
                    </h3>
                    <StyledDataInfographic src={ImgGetDataInfographic} alt="" />
                  </Col>
                </Row>
              </StyledContentOverlay>
            </Col>
          </Row>

          <Row className="mb-5" data-scroll-section>
            <Col xs="12">
              <h3>How do we use the data?</h3>
            </Col>
            <Col xs="12">
              <DataCreativitySlider />
            </Col>
          </Row>

          <Row className="mb-5" data-scroll-section>
            <Col xs="12" md="6">
              <StyledDominoesImg
                src={ImgDominoes}
                alt=""
                className="img-fluid reveal-left"
                data-scroll
                data-scroll-speed="2"
                data-scroll-repeat={true}
              />
              <StyledFadeCircle data-scroll data-scroll-speed="1.4">
                <FadeCircleSmall />
              </StyledFadeCircle>
            </Col>
            <Col xs="12" md="6">
              <h3>Why do we do this?</h3>
              <p>
                We believe that more refined information leads to better design
                decisions.
              </p>

              <h4>Good data = Good everything</h4>

              <ul>
                <li>
                  More efficient allocation, selection, and usage of space.
                </li>
                <li>
                  Improved retention and attraction of employees and/or
                  customers.
                </li>
                <li>Measurable accountability and KPI's.</li>
                <li>Improved transparency as the data is without bias.</li>
                <li>Adds a competitive edge.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <WhyChooseUs />
      </PageTemplate>
    </StyledDataCreativityPage>
  )
}

export default DataCreativityPage

const StyledDataCreativityPage = styled.div`
  h3 {
    font-family: ${vars.secondaryFont};
    font-size: 46px;
    font-weight: normal;
    margin-bottom: 24px;

    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 32px;
    }
  }

  h4 {
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: 500;

    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 18px;
    }
  }

  ul {
    padding-left: 16px;

    li {
      margin-bottom: 8px;
    }
  }

  .fade-in-slide {
    opacity: 0;
    transform: translate(50px, 0px);
  }

  .data-creativity-banner {
    opacity: 0;
  }

  .img-human-truth {
    padding-bottom: 52px;
  }
`

const StyledTitle = styled.h2`
  font-size: 56px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 5px;
  margin-bottom: 0;

  span {
    display: block;
  }

  .data-creativity-title-1 {
    margin-bottom: 5px;
    opacity: 0;
    transform: translate(-50px, 0px);
  }

  .data-creativity-title-2 {
    margin-left: 100px;
    opacity: 0;
    transform: translate(-50px, 0px);
  }

  @media (max-width: ${vars.screen_xs_max}) {
    font-size: 38px;
    margin-bottom: 0;
  }
`

const StyledCircle = styled.div`
  position: absolute;
  z-index: 10;
  left: -30%;
  width: 160%;
  display: block;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 50%;
  top: 0;
  aspect-ratio: 1 / 1;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`

const StyledContentOverlay = styled.div`
  position: relative;
  z-index: 20;
  mix-blend-mode: multiply;
  padding: 80px 0 180px 0;

  @media (max-width: ${vars.screen_md_max}) {
    padding: 30px 0;
  }
`

const StyledQuote = styled.blockquote`
  font-family: ${vars.secondaryFont};
  font-size: 46px;
  margin: 0 0 20px 0;

  @media (max-width: ${vars.screen_md_max}) {
    font-size: 32px;
  }
`

const StyledDataInfographic = styled.img`
  max-width: 900px;
  width: 100%;
  margin: -70px auto 0 auto;
  display: block;

  @media (max-width: ${vars.screen_md_max}) {
    margin-top: 0;
  }
`

const StyledFadeCircle = styled.div`
  position: absolute;
  width: 398px;
  height: 398px;
  background: #e9f2eb;
  top: -124px;
  left: -50px;
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  z-index: 10;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledDominoesImg = styled.img`
  position: relative;
  z-index: 20;

  @media (max-width: ${vars.screen_sm_max}) {
    margin-bottom: 76px;
  }
`
