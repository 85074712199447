import React, { useRef, useLayoutEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import vars from "styles/variables"
import ProjectBlock from "./ProjectBlock"
import { createScrollTriggerAnimation } from "utils/animationUtil"
import WhyChooseUs from "components/core/WhyChooseUs"

export default function ProjectDetailsSlider({ data }) {
  const ref = useRef(null)
  const scrollTweenRef = useRef()

  useLayoutEffect(() => {
    if (!ref.current) return

    // This does not work without a settimeout
    setTimeout(() => {
      let sections = gsap.utils.toArray(".project-item-wrapper")

      scrollTweenRef.current = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          id: "project-slider",
          start: "top top",
          trigger: ref.current,
          scroller: "#main-container",
          pin: true,
          scrub: true,
          end: () => `+=${ref.current.offsetWidth * 4}`,
        },
      })

      ScrollTrigger.matchMedia({
        "(min-width: 992px)": function () {
          ScrollTrigger.refresh()
        },
      })

      const scroller = scrollTweenRef.current

      createScrollTriggerAnimation(scroller, ".gsap-slide-left-1", {
        x: "-50vw",
      })

      createScrollTriggerAnimation(
        scroller,
        ".gsap-slide-left-2",
        { x: "-50vw" },
        { end: "bottom -150%" }
      )

      createScrollTriggerAnimation(
        scroller,
        ".gsap-slide-left-3",
        { x: "-50vw" },
        { end: "bottom -200%" }
      )

      createScrollTriggerAnimation(scroller, ".gsap-slide-up-1", {
        y: "-20vw",
      })

      createScrollTriggerAnimation(scroller, ".gsap-slide-up-2", {
        y: "-40vw",
      })

      createScrollTriggerAnimation(
        scroller,
        ".gsap-slide-up-end-1",
        {
          y: 0,
        },
        { end: "center center" }
      )

      createScrollTriggerAnimation(scroller, ".gsap-slide-down-1", {
        y: "20vw",
      })

      createScrollTriggerAnimation(scroller, ".gsap-fade-out-1", { opacity: 0 })

      createScrollTriggerAnimation(
        scroller,
        ".gsap-clip-circle-1",
        { clipPath: "circle(50%)" },
        { end: "center center" }
      )

      createScrollTriggerAnimation(
        scroller,
        ".gsap-scale-up-1",
        { scale: 1 },
        { end: "center center" }
      )
    })

    return () => {
      const scrollTriggerToKill = ScrollTrigger.getById("project-slider")
      if (scrollTriggerToKill) {
        scrollTriggerToKill.kill(true)
      }
    }
  }, [])

  return (
    <StyledProjectSlider ref={ref}>
      {data.blocks.map(item => {
        return (
          <div key={item.id} className="project-item-wrapper">
            <div className="project-item">
              <ProjectBlock data={item} />
            </div>
          </div>
        )
      })}
      <div className="project-item-wrapper">
        <div className="project-item">
          <div className="choose-us-wrapper gsap-slide-up-2">
            <WhyChooseUs isAnimationActive={false} />
          </div>
        </div>
      </div>
    </StyledProjectSlider>
  )
}

const StyledProjectSlider = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${vars.screen_md_max}) {
    overflow-x: scroll;
    padding: 0 0 40px 44px;
    height: 660px;
  }

  @media (max-width: ${vars.screen_xs_max}) {
    height: 475px;
  }

  .choose-us-wrapper {
    margin-top: 75vh;
  }

  .project-item-wrapper {
    height: 100%;
    display: grid;
    width: 100vw;
    position: relative;

    @media (max-width: ${vars.screen_md_max}) {
      width: 100vw;
    }
  }

  .project-item {
    width: 100vw;
    height: 100%;
    position: relative;
    will-change: transform;
  }
`
