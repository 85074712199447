import config from "config"
import httpService from "services/httpService"

const endpoint = `${config.api}/Article`

export async function getArticleList(articleType) {
  const data = await httpService.get(`${endpoint}/ArticleList`, {
    params: { articleType },
  })
  return data
}

export async function getArticleListFiltered(articleType, currentPage = 1) {
  const data = await httpService.get(`${endpoint}/ArticleListFiltered`, {
    params: { articleType, currentPage },
  })
  return data
}

export async function getArticleDetails(articleShortName) {
  const data = await httpService.get(`${endpoint}/ArticleDetails`, {
    params: { articleShortName },
  })
  return data
}

export async function getVideoList() {
  const data = await httpService.get(`${endpoint}/VideoList`)
  return data
}
