import React from "react"
import { useParams } from "react-router-dom"
import { getProjectDetails } from "services/projectsService"
import LoadingIcon from "components/common/LoadingIcon"
import useFetch from "hooks/useFetch"
import useLocoScroll from "hooks/useLocoScroll"
import ProjectDetailsSlider from "components/core/ProjectDetailsSlider"

function ProjectDetailsPage() {
  let { slug } = useParams()
  const { data, isLoading } = useFetch(() => getProjectDetails(slug))

  useLocoScroll(!isLoading)

  if (isLoading) return <LoadingIcon isFullScreen />

  return (
    <div data-scroll-section>
      <ProjectDetailsSlider data={data} />
    </div>
  )
}

export default ProjectDetailsPage
