import vars from "styles/variables"
import { createGlobalStyle, css } from "styled-components"

export const HideBodyOverflow = createGlobalStyle`
  body{
    overflow: hidden;
  }
`

const styles = css`
  *,
  *:focus,
  *:hover {
    outline: none;
  }

  @media (min-width: ${vars.screen_lg_min}) {
    html,
    body {
      &,
      * {
        cursor: none !important;
      }
    }
  }

  body {
    height: 100vh;
    margin: 0;
    font-family: ${vars.primaryFont};
    background: ${vars.white};
    color: ${vars.black};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-rendering: geometricPrecision;

    &.is-cursor-large {
      .app-cursor-inner {
        transform: scale(2);
      }
    }

    &.hide-main {
      main {
        opacity: 0;
      }
    }
  }

  #cursor {
    --size: 27px;
    position: fixed;
    top: 0;
    left: 0;
    height: var(--size);
    width: var(--size);
    background-color: #f1f1f1;
    border-radius: 50%;
    transform: translate3d(
      calc(100vw * var(--cursor-x) - var(--size) / 2),
      calc(100vh * var(--cursor-y) - var(--size) / 2),
      0
    );
    pointer-events: none;
    z-index: 999999;
    mix-blend-mode: difference;

    .app-cursor-inner {
      transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      background: #f1f1f1;
    }

    @media (max-width: ${vars.screen_md_max}) {
      display: none;
    }
  }

  .nav-open {
    overflow: hidden;
  }

  textarea {
    font-family: ${vars.primaryFont};
  }

  main {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  h1,
  h2,
  h3 {
    margin-top: 0;
    font-weight: bold;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  .font-secondary {
    font-family: ${vars.secondaryFont};
  }

  a {
    text-decoration: none;
    color: ${vars.black};
  }

  p {
    margin-top: 0;
  }

  .error-text {
    color: ${vars.red};
  }

  .full-width,
  .w-100 {
    width: 100%;
  }

  .img-fluid {
    max-width: 100%;
  }

  .img-circle {
    border-radius: 50%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .heading {
    border-left: 16px solid transparent;
    padding-left: 8px;
    position: relative;

    :before {
      content: "";
      width: 16px;
      height: calc(100% - 10px);
      top: 5px;
      left: -16px;
      position: absolute;
      background: ${vars.primary};
    }
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
    margin: 0;
  }

  .slick-slide div {
    outline: none;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${vars.grey_500};
  }

  .box-hover {
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* display: block; */
      transition: ease-in-out 500ms;
    }

    &:hover {
      color: ${vars.white};

      .img {
        filter: blur(3px);
        transform: scale(1.1);
      }
    }
  }

  .react-loading-skeleton::after {
    top: 0;
  }

  ${[1, 2, 3, 4, 5].map(
    x => `.bg-grey_${x}00 { background-color: ${vars[`grey_${x}00`]}; }`
  )}

  .sticky {
    position: sticky;
    top: 1.5rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .animate-slide-down {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);

    animation: slide-in-horizontal 0.5s forwards;
    -webkit-animation: slide-in-horizontal 0.5s forwards;
  }

  .animate-slide-in-left {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);

    animation: slide-in-vertical 0.5s forwards;
    -webkit-animation: slide-in-vertical 0.5s forwards;
  }

  .animate-slide-in-right {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);

    animation: slide-in-vertical 0.5s forwards;
    -webkit-animation: slide-in-vertical 0.5s forwards;
  }

  @keyframes slide-in-vertical {
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-in-horizontal {
    100% {
      transform: translateX(0%);
    }
  }

  .animate-fade-in {
    animation: fade-in 0.5s forwards;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .max-width-500 {
    max-width: 500px;
  }

  .margin-center {
    margin-left: auto;
    margin-right: auto;
  }

  .fs-14px {
    font-size: 14px;
  }

  .primary-button {
    font-size: 16px;
    height: 42px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-decoration: none;
    padding: 0px 1.5rem;
    cursor: pointer;
    line-height: 1;
    border: none;
    color: rgb(255, 255, 255);
    opacity: 1;
    transition: all 200ms ease-in-out 0s;
    background: rgb(0, 168, 216);
    border-radius: 0px;
    font-family: sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    user-select: none;
    z-index: 10;

    &:hover,
    &:disabled,
    &[disabled] {
      background: ${vars.primaryDark};
    }
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .reveal-up {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);

    &.is-reveal {
      opacity: 1;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  .reveal-left {
    clip-path: polygon(0 100%, 0 100%, 0 0, 0 0);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);

    &.is-reveal {
      opacity: 1;
      clip-path: polygon(100% 100%, 0 100%, 0 0, 100% 0);
    }
  }

  .reveal-right {
    clip-path: polygon(100% 100%, 100% 100%, 100% 0, 100% 0);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);

    &.is-reveal {
      opacity: 1;
      clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
    }
  }

  .reveal-circle {
    clip-path: circle(10%);
    transition: clip-path 1.8s cubic-bezier(0.77, 0, 0.175, 1);

    &.is-reveal {
      opacity: 1;
      clip-path: circle(100%);
    }
  }
`

export default createGlobalStyle`
  ${styles}
`
