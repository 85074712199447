import * as React from "react"

const CircleBulbIcon = props => (
  <svg
    width={115}
    height={115}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#CircleBulbIcon)">
      <path
        d="M57.5 115C25.798 115 0 89.202 0 57.5S25.798 0 57.5 0 115 25.798 115 57.5 89.202 115 57.5 115Zm0-109.565C28.793 5.449 5.45 28.793 5.45 57.5c0 28.707 23.357 52.051 52.05 52.051 28.693 0 52.051-23.358 52.051-52.051S86.207 5.45 57.5 5.45v-.015Z"
        fill="#A8D7B0"
      />
      <path
        d="M68.657 63.12c-2.285 3.164-3.931 6.64-4.882 10.288H51.259c-.937-3.676-2.569-7.138-4.796-10.132a13.688 13.688 0 0 1-2.725-8.245c.014-7.35 6.23-13.637 13.552-13.75h.199c3.632 0 7.052 1.404 9.65 3.958 2.653 2.611 4.1 6.102 4.1 9.82 0 2.924-.894 5.72-2.61 8.075m-5.847 17.752a.412.412 0 0 1-.412.412H52.58a.412.412 0 0 1-.412-.412c0-1.334-.085-2.668-.27-3.987h11.154a28.66 28.66 0 0 0-.27 3.987Zm-5.563-43.082c-4.47.07-8.727 1.915-11.963 5.193-3.235 3.292-5.023 7.564-5.037 12.034 0 3.76 1.178 7.336 3.405 10.345 1.788 2.398 3.122 5.137 3.974 8.046H45.2v3.49h3.164c.199 1.306.298 2.64.298 3.974a3.91 3.91 0 0 0 3.903 3.902h9.777a3.91 3.91 0 0 0 3.902-3.902c0-1.334.1-2.668.312-3.987h3.165v-3.491h-2.37a27.232 27.232 0 0 1 4.087-8.23c2.143-2.953 3.264-6.458 3.264-10.119 0-4.654-1.83-9.025-5.151-12.303-3.307-3.264-7.692-5.023-12.346-4.967"
        fill="#6FC282"
      />
      <path
        d="M61.074 55.215h-4.002l4.087-6.499-2.951-1.859-5.762 9.167a1.73 1.73 0 0 0-.042 1.774c.312.553.894.894 1.532.894h3.974l-4.13 6.47 2.938 1.874 5.846-9.153c.34-.539.369-1.22.057-1.774a1.75 1.75 0 0 0-1.533-.908M59.245 30.212h-3.491v5.236h3.49v-5.236ZM40.712 36.41l-2.47 2.467 3.7 3.704 2.47-2.467-3.7-3.704ZM74.302 36.41l-3.7 3.705 2.47 2.467 3.7-3.705-2.47-2.467Z"
        fill="#6FC282"
      />
    </g>
    <defs>
      <clipPath id="CircleBulbIcon">
        <path fill="#fff" d="M0 0h115v115H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default CircleBulbIcon
