import * as React from "react"

function ArrowLeftIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#000"
          className="svg-fill"
          d="M14 18l1.41-1.41L10.83 12l4.58-4.59L14 6l-6 6z"
        />
        <path d="M24 24H0V0h24z" />
      </g>
    </svg>
  )
}

export default ArrowLeftIcon
