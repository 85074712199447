import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import loadable from "@loadable/component"
import NotFoundPage from "pages/error/NotFoundPage"
import HomePage from "pages/home/HomePage"
import ForbiddenPage from "pages/error/ForbiddenPage"
import LoadingIcon from "components/common/LoadingIcon"
import AboutUsPage from "pages/about/AboutUsPage"
import TeamPage from "pages/team/TeamPage"
import ProjectsPage from "pages/projects/ProjectsPage"
import ProjectDetailsPage from "pages/projects/ProjectDetailsPage"
import CookiePolicyPage from "pages/home/CookiePolicyPage"
import AdvisorsPage from "pages/team/AdvisorsPage"
import DataCreativityPage from "pages/data-creativity/DataCreativityPage"
import EnvironmentPage from "pages/environment/EnvironmentPage"
// import ContactUsPage from "pages/contact/ContactUsPage"

const AdminRouting = loadable(() => import("components/core/AdminRouting"), {
  fallback: <LoadingIcon colour="primary" width={120} isFullScreen />,
})

function Routing() {
  return (
    <Switch>
      <Route path="/admin" component={AdminRouting} />
      <Route exact path="/cookie-policy" component={CookiePolicyPage} />
      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route exact path="/projects/:slug" component={ProjectDetailsPage} />
      <Route exact path="/projects" component={ProjectsPage} />
      <Route exact path="/advisors" component={AdvisorsPage} />
      <Route exact path="/meet-the-team" component={TeamPage} />
      <Route exact path="/about-us" component={AboutUsPage} />
      <Route exact path="/data-x-creativity" component={DataCreativityPage} />
      <Route exact path="/environment" component={EnvironmentPage} />
      <Route exact path="/" component={HomePage} />
      <Route exact to="/not-found" component={NotFoundPage} />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routing
