import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import PageTemplate from "templates/PageTemplate"
import useLocoScroll from "hooks/useLocoScroll"
import useImagePreloader from "hooks/useImagePreloader"
import Seo from "components/common/Seo"
import useEnvironment from "hooks/useEnvironment"
import WhyChooseUs from "components/core/WhyChooseUs"
import EnvironmentSlider from "components/core/EnvironmentSlider"
import BackToTop from "components/common/BackToTop"
import ImgBanner from "assets/images/enviro-banner.png"
import ImgCarbonGraph from "assets/images/carbon-graph.jpg"
import ImgNetZero from "assets/images/net-zero-banner.jpg"
import ImgCO2_1 from "assets/images/enviro-co2-1.png"
import ImgCO2_2 from "assets/images/enviro-co2-2.png"
import ImgCO2_3 from "assets/images/enviro-co2-3.png"
import ImgRecycle from "assets/images/enviro-recycle.png"
import ImgSlotGroove from "assets/images/enviro-slot-groove.png"
import ImgModularity from "assets/images/enviro-modularity.png"
import ImgExperiment from "assets/images/enviro-experiment.png"

const preloadSrcList = [
  ImgBanner,
  ImgCarbonGraph,
  ImgNetZero,
  ImgCO2_1,
  ImgCO2_2,
  ImgCO2_3,
  ImgRecycle,
  ImgSlotGroove,
  ImgModularity,
  ImgExperiment,
]

export default function EnvironmentPage() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const { locoScrollTop } = useLocoScroll(imagesPreloaded)
  const { environmentRef } = useEnvironment(imagesPreloaded)

  if (!imagesPreloaded) return null

  return (
    <StyledEnvironmentPage ref={environmentRef}>
      <Seo title="Environment" />
      <BackToTop onClick={locoScrollTop} />
      <PageTemplate title="Environment" hideTitle>
        <Container>
          <Row className="mb-5 align-items-center" data-scroll-section>
            <Col xs="12" md="6" lg="7">
              <StyledTitle className="enviro-title">
                <div
                  data-scroll
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                >
                  <span>N</span>
                </div>
                <div
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                >
                  <span>E</span>
                </div>
                <div
                  data-scroll
                  data-scroll-speed="0.5"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                >
                  <span>T</span>
                </div>

                <div
                  data-scroll
                  data-scroll-speed="-1"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                >
                  <span>ZERO</span>
                </div>
              </StyledTitle>
              <div className="enviro-quote">
                <StyledQuote data-scroll data-scroll-speed="2">
                  &#8220;So we can&#8217;t save the world by playing by the
                  rules, because the rules have to be changed. Everything needs
                  to change and it has to start today&#8221;
                </StyledQuote>
                <p data-scroll data-scroll-speed="1.5">
                  Greta Thunberg
                  <br />
                  Activist
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="5">
              <div className="enviro-banner">
                <img
                  src={ImgBanner}
                  alt=""
                  className="img-fluid mb-4 mb-md-0"
                  data-scroll
                  data-scroll-speed="-2"
                  data-scroll-direction="horizontal"
                />
              </div>
            </Col>
          </Row>

          <Row data-scroll-section>
            <Col xs="12" md="6" lg="4" className="mt-4 carbon-title">
              <h3
                data-scroll
                data-scroll-speed="-1"
                data-scroll-direction="horizontal"
                data-scroll-position="left"
              >
                Carbon Net Zero Pledge
              </h3>
            </Col>
          </Row>

          <Row className="mb-5 align-items-center" data-scroll-section>
            <Col xs="12" lg="7">
              <img
                src={ImgNetZero}
                alt="Net Zero Graph"
                className="img-fluid mb-4 mb-md-0"
              />
            </Col>
            <Col xs="12" lg="5" data-scroll data-scroll-speed="3">
              <p>
                We&#8217;ll provide our clients with a report at the end of RIBA
                Stage 2: Concept, identifying how your project can achieve
                carbon neutrality operationally and how it can be embodied.
              </p>

              <p>
                We&#8217;ll work with the appointed engineering, quantity
                surveyor and project management teams to ensure we identify cost
                and programme implications.
              </p>

              <p>
                Our role is to guide the way towards a carbon neutral future,
                whilst continuing to be mindful that, in some instances,
                there&#8217;ll still be an offsetting requirement due to site
                location, compliance restrictions or product and material
                limitations. However, we expect continual offsetting reductions
                as the world moves to meet the COP26 goals.
              </p>

              <p>
                Our future is dependent on us making the right decisions now,
                and we believe in being part of the solution.
              </p>
            </Col>
          </Row>

          <Row className="mb-5" data-scroll-section>
            <Col>
              <StyledCircle data-scroll data-scroll-speed="2" />
              <StyledContentOverlay>
                <Row>
                  <Col xs="12" md="6">
                    <h3>What we do?</h3>
                    <p className="mb-5">
                      We look at geo-spatial data that are relevant to the
                      building occupiers and focusing on datasets that we
                      believe are important to consider before we start the
                      design process.
                    </p>
                  </Col>
                  <Col xs="12">
                    <img
                      src={ImgCarbonGraph}
                      alt="Carbon Graph"
                      className="img-fluid mb-4"
                    />
                  </Col>
                  <Col xs="12">
                    <h3>Our intention</h3>
                  </Col>
                  <Col xs="12" md="6" lg="5" className="mb-4">
                    <p>
                      Moving from a linear model towards a circular one is our
                      major goal. This does not happen overnight. Most globals,
                      by 2025, are aiming to not only to reduce their Co2
                      footprint per product by 15%, but to achieve climate
                      neutrality in own operations. And by 2030, they want to
                      reduce CO2 emissions by 30% along the entire value chain.
                    </p>
                  </Col>
                  <Col xs="12" md="6" className="offset-lg-1 mb-4">
                    <StyledQuote>
                      ...reduce the carbon in everything we do, we make, we
                      waste
                    </StyledQuote>
                  </Col>
                </Row>
                <Row className="mt-5 align-items-center">
                  <Col xs="12" lg="5">
                    <img
                      src={ImgCO2_1}
                      alt="CO2 1"
                      className="img-fluid mb-4"
                      data-scroll
                      data-scroll-speed="1.5"
                    />
                  </Col>
                  <Col xs="12" lg="2">
                    <img
                      src={ImgCO2_2}
                      alt="CO2 2"
                      className="img-fluid mb-4 co2-2"
                    />
                  </Col>
                  <Col xs="12" lg="5">
                    <img
                      src={ImgCO2_3}
                      alt="CO2 3"
                      className="img-fluid mb-4"
                      data-scroll
                      data-scroll-speed="-1.5"
                    />
                  </Col>
                </Row>
              </StyledContentOverlay>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6" className="mb-5" data-scroll-section>
              <h3>Circular design at the core</h3>
              <p>
                Think about our interiors being made from re-used materials.
                These re-used materials can be disassembled at the end of a
                fitout&#8217;s life.
              </p>
            </Col>
          </Row>
        </Container>
        <div data-scroll-section className="mb-5">
          <EnvironmentSlider />
        </div>
        <WhyChooseUs />
      </PageTemplate>
    </StyledEnvironmentPage>
  )
}

const StyledEnvironmentPage = styled.div`
  h3 {
    margin: 0 0 16px 0;
    font-size: 24px;
    font-weight: 600;

    @media (max-width: ${vars.screen_sm_max}) {
      font-size: 18px;
    }
  }

  ul {
    padding-left: 16px;

    li {
      margin-bottom: 8px;
    }
  }

  .fade-in-slide {
    opacity: 0;
    transform: translate(50px, 0px);
  }

  .co2-2 {
    max-width: 100px;
    margin: 0 auto;
    display: block;
  }

  .enviro-quote,
  .carbon-title {
    opacity: 0;
    transform: translate(0, 50px);
  }

  .enviro-banner {
    opacity: 0;
    transform: translate(50px, 50px);
  }
`

const StyledTitle = styled.h2`
  font-size: 56px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 5px;
  margin-bottom: 30px;

  span {
    display: block;
    opacity: 0;
    transform: rotate(-45deg);
    transform: translate(40px, 40px);
  }
`

const StyledQuote = styled.blockquote`
  font-family: ${vars.secondaryFont};
  font-size: 46px;
  margin: 0 0 20px 0;

  @media (max-width: ${vars.screen_md_max}) {
    font-size: 32px;
  }
`
const StyledCircle = styled.div`
  position: absolute;
  z-index: 10;
  left: -30%;
  width: 160%;
  display: block;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 50%;
  top: 0;
  aspect-ratio: 1 / 1;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`

const StyledContentOverlay = styled.div`
  position: relative;
  z-index: 20;
  mix-blend-mode: multiply;
  padding: 80px 0 180px 0;

  @media (max-width: ${vars.screen_md_max}) {
    padding: 30px 0;
  }
`
