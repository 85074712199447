import React, { useRef, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Col, Row } from "reactstrap"
import TextField from "components/common/TextField"
import Button from "components/common/Button"
import { toastError, toastSuccess } from "utils/toastUtil"
import { submitContactForm } from "services/contactService"

function ContactForm({ handleFooterToggle }) {
  const isMounted = useRef(true)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
  })

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  async function onSubmit(formData) {
    setIsSubmitLoading(true)
    const response = await submitContactForm(formData)

    if (!isMounted.current) return
    if (!response.isSuccess) return handleError(response.responseMessage)

    reset()
    setIsSubmitLoading(false)
    handleFooterToggle()
    toastSuccess("Thank you, we will be in touch")
  }

  function handleError(error) {
    toastError(error)
    if (!isMounted.current) return
    setIsSubmitLoading(false)
  }

  return (
    <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col xs="12" sm="6">
          <TextField
            required
            control={control}
            name="name"
            error={formState.errors.name}
            placeholder="Name..."
          />
        </Col>
        <Col>
          <TextField
            required
            control={control}
            name="email"
            error={formState.errors.email}
            placeholder="Email..."
          />
        </Col>
      </Row>

      <TextField
        required
        control={control}
        name="message"
        error={formState.errors.message}
        placeholder="Message..."
        multiline
      />

      <Button type="submit" isLoading={isSubmitLoading} isFullWidth>
        Enquire Now
      </Button>
    </form>
  )
}

export default ContactForm
