import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import PageTemplate from "templates/PageTemplate"
import LoadingIcon from "components/common/LoadingIcon"
import vars from "styles/variables"
import { HideBodyOverflow } from "styles/globalStyles"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import useTeam from "hooks/useTeam"
import { noImage } from "utils/imageUtil"
import Seo from "components/common/Seo"

export default function TeamPage() {
  const {
    teamRef,
    isLoading,
    isTeamMemberHover,
    activeMember,
    featuredTeamMembers,
    teamMembers,
    setIsTeamMemberHover,
    handleActiveMember,
    handleCloseActiveMember,
  } = useTeam()

  if (isLoading) return <LoadingIcon width={60} colour="primary" isFullScreen />

  return (
    <StyledTeamPage
      ref={teamRef}
      className={`${isTeamMemberHover ? "is-team-member-hover" : ""}`}
    >
      <PageTemplate hideTitle>
        <Seo title="Meet the Team" />
        <Container>
          <Row>
            <Col xs="12" xl="5">
              <h1 className="team-title">
                <span className="rotate-1 rotate">m</span>
                <span className="rotate-2 rotate">e</span>
                <span className="rotate-3 rotate">e</span>
                <span className="rotate-4 rotate">t</span>
                <span className="slide-in">the team</span>
              </h1>
            </Col>
            <Col>
              {featuredTeamMembers.length > 0 && (
                <Row className="justify-content-center featured-member-list">
                  {featuredTeamMembers.map(item => (
                    <Col xs="12" md="6" key={item.id}>
                      <TeamMember
                        data={item}
                        onClick={() => handleActiveMember(item)}
                        onMouseEnter={enlargeCursorSize}
                        onMouseLeave={resetCursorSize}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>

          {teamMembers.length > 0 && (
            <Row className="row-cols-lg-5 justify-content-center team-member-list">
              {teamMembers.map(item => (
                <Col
                  xs="12"
                  sm="6"
                  md="3"
                  key={item.id}
                  className="team-member-standard"
                >
                  <TeamMember
                    data={item}
                    onClick={() => handleActiveMember(item)}
                    onMouseEnter={() => {
                      setIsTeamMemberHover(true)
                      enlargeCursorSize()
                    }}
                    onMouseLeave={() => {
                      setIsTeamMemberHover(false)
                      resetCursorSize()
                    }}
                  />
                </Col>
              ))}
            </Row>
          )}
        </Container>

        {activeMember && (
          <StyledActiveMember
            onClick={handleCloseActiveMember}
            className="animate-fade-in"
          >
            <HideBodyOverflow />
            <Container>
              <Row>
                <Col xs="12" md="4" className="mb-4">
                  <StyledTeamMemberImg
                    src={activeMember.image || noImage}
                    loading="lazy"
                    className="w-100"
                    isColour
                  />
                </Col>
                <Col xs="12" md="8">
                  <div onClick={e => e.stopPropagation()}>
                    <h2 className="mb-2">
                      {activeMember.firstName} {activeMember.surname}
                    </h2>
                    <p className="text-uppercase mb-0">
                      {activeMember.jobTitle}
                    </p>

                    <p className="my-4">
                      {activeMember.email && (
                        <a href={`mailto:${activeMember.email}`}>
                          {activeMember.email}
                        </a>
                      )}
                      {activeMember.phone && (
                        <>
                          <span className="d-none d-sm-inline-block mx-3">
                            |
                          </span>
                          <br className="d-sm-none" />
                          <a
                            href={`tel:${activeMember.phone}`}
                            className="text-nowrap"
                          >
                            {activeMember.phone}
                          </a>
                        </>
                      )}
                      {activeMember.phone2 && (
                        <>
                          <span className="d-none d-sm-inline-block mx-3">
                            |
                          </span>
                          <br className="d-sm-none" />
                          <a
                            href={`tel:${activeMember.phone2}`}
                            className="text-nowrap"
                          >
                            {activeMember.phone2}
                          </a>
                        </>
                      )}
                    </p>

                    <div
                      dangerouslySetInnerHTML={{ __html: activeMember.bio }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </StyledActiveMember>
        )}
      </PageTemplate>
    </StyledTeamPage>
  )
}

function TeamMember({ data, ...rest }) {
  return (
    <StyledTeamMember {...rest} className="team-member">
      <StyledTeamMemberImg
        src={data.image || noImage}
        loading="lazy"
        className="w-100 team-member-img"
      />
      {data.isFeatured && (
        <StyledTeamMemberOverlay className="team-featured-overlay">
          <h2 className="mb-2">
            {data.firstName} {data.surname}
          </h2>
          <p className="mb-0 text-uppercase">{data.jobTitle}</p>
        </StyledTeamMemberOverlay>
      )}
      {!data.isFeatured && (
        <StyledTeamMemberInfoOverlay className="team-info-overlay">
          <h2 className="mb-2">
            {data.firstName} {data.surname}
          </h2>
          <p className="mb-3 text-uppercase team-job-title">{data.jobTitle}</p>
          {data.email && (
            <>
              <a href={`mailto:${data.email}`}>{data.email}</a>
              <br />
            </>
          )}
          {data.phone && <a href={`tel:${data.phone}`}>{data.phone}</a>}
        </StyledTeamMemberInfoOverlay>
      )}
    </StyledTeamMember>
  )
}

const StyledTeamPage = styled.div`
  h1 {
    font-size: 74px;
    letter-spacing: 10px;
    line-height: 1;
    position: relative;
    padding-bottom: 250px;

    @media (max-width: ${vars.screen_xs_max}) {
      font-size: 50px;
      padding-bottom: 230px;
    }

    span {
      left: 0;
      &.slide-in {
        opacity: 0;
        transform: translate(40px, 0);
        display: block;
      }

      &.rotate {
        position: absolute;
        line-height: 1;
        opacity: 0;
        transform: translate(40px, 40px);
      }

      &.rotate-4 {
        bottom: 174px;
        left: 7px;
        @media (max-width: ${vars.screen_xs_max}) {
          bottom: 174px;
          left: 7px;
        }
      }
      &.rotate-3 {
        bottom: 126px;
        left: -1px;
        @media (max-width: ${vars.screen_xs_max}) {
          bottom: 139px;
          left: 1px;
        }
      }
      &.rotate-2 {
        bottom: 71px;
        left: -1px;
        @media (max-width: ${vars.screen_xs_max}) {
          bottom: 98px;
          left: 1px;
        }
      }
      &.rotate-1 {
        bottom: -2px;
        left: -18px;
        @media (max-width: ${vars.screen_xs_max}) {
          bottom: 46px;
          left: -10px;
        }
      }
    }
  }

  .team-member {
    opacity: 0;
    transform: translate(0, 40px);
  }

  &.is-team-member-hover {
    .team-member-img {
      opacity: 0.1;
    }
  }

  @media (min-width: ${vars.screen_sm_min}) and (max-width: ${vars.screen_sm_max}) {
    .team-member-list {
      .team-member-standard:nth-child(2n) {
        .team-info-overlay {
          left: 0;
          text-align: right;
          transform: translate(-100%, -50%);
          padding-right: 30px;
        }
      }
    }
  }

  @media (min-width: ${vars.screen_md_min}) and (max-width: ${vars.screen_md_max}) {
    .team-member-list {
      .team-member-standard:nth-child(4n) {
        .team-info-overlay {
          left: 0;
          text-align: right;
          transform: translate(-100%, -50%);
          padding-right: 30px;
        }
      }
    }
  }

  @media (min-width: ${vars.screen_lg_min}) {
    .team-member-list {
      .team-member-standard:nth-child(5n) {
        .team-info-overlay {
          left: 0;
          text-align: right;
          transform: translate(-100%, -50%);
          padding-right: 30px;
        }
      }
    }
  }

  .team-member-standard {
    @media (max-width: ${vars.screen_xs_max}) {
      .team-member-img {
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
`

const StyledTeamMember = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;

  &:hover {
    overflow: visible;

    .team-featured-overlay,
    .team-info-overlay {
      opacity: 1;
    }

    img {
      opacity: 1 !important;
    }
  }
`

const StyledTeamMemberImg = styled.img`
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  clip-path: circle(50%);
  filter: grayscale(${props => (props.isColour ? 0 : 1)});
`

const StyledTeamMemberOverlay = styled.div`
  position: absolute;
  background: ${vars.blackTransparent()};
  top: 50%;
  left: 50%;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${vars.white};
  text-align: center;
  opacity: 0;
  transition: ease-in-out 500ms;
`

const StyledTeamMemberInfoOverlay = styled.div`
  transition: ease-in-out 500ms;

  @media (min-width: ${vars.screen_sm_min}) {
    position: absolute;
    top: 50%;
    left: 110%;
    z-index: 10;
    min-width: 222px;
    transform: translate(0, -50%);
    opacity: 0;
  }

  @media (max-width: ${vars.screen_xs_max}) {
    padding-top: 30px;
    text-align: center;
  }

  .team-job-title {
    font-size: 14px;
  }

  a {
    clear: both;
  }
`

const StyledActiveMember = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${vars.white};
  padding-top: 130px;
  overflow-y: scroll;

  @media (max-width: ${vars.screen_xs_max}) {
    padding: 50px 0 80px 0;
  }
`
