import React from "react"
import { Container, Col, Row } from "reactstrap"
import PageTemplate from "templates/PageTemplate"

function ForbiddenPage() {
  return (
    <PageTemplate>
      <Container fluid>
        <Row>
          <Col className="text-danger">
            <h1>403: Forbidden</h1>
            <p>You do not have permission to access this page.</p>
          </Col>
        </Row>
      </Container>
    </PageTemplate>
  )
}

export default ForbiddenPage
