import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  createContext,
} from "react"
import { gsap } from "gsap"
import { useFooter } from "hooks/useFooter"

const Context = createContext()

// Provider component that wraps your app and makes our object
// available to any child component
export function NavProvider({ children }) {
  const value = useProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}

// Hook for child components to get the object
// and re-render when it changes
export const useNav = () => {
  return useContext(Context)
}

const navItemData = [
  {
    title: "About us",
    url: "/about-us",
    image: require("assets/images/nav-image-about-us.jpg"),
  },
  {
    title: "Projects",
    url: "/projects",
    image: require("assets/images/nav-image-projects.jpg"),
  },
  {
    title: "Meet the team",
    url: "/meet-the-team",
    image: require("assets/images/nav-image-people.jpg"),
  },
  {
    title: "Our advisors",
    url: "/advisors",
    image: require("assets/images/nav-image-advisors.jpg"),
  },
  {
    title: "Data x Creativity",
    url: "/data-x-creativity",
    image: require("assets/images/nav-image-data-creativity.jpg"),
  },
  {
    title: "Environment",
    url: "/environment",
    image: require("assets/images/nav-image-environment.jpg"),
  },
  {
    title: "Contact",
    url: "/contact",
    image: require("assets/images/nav-image-contact.jpg"),
    isContact: true,
  },
]

function useProvider() {
  const animationRunning = useRef(false)
  const navRef = useRef()
  const navItemRef = useRef(navItemData)
  const navItemHoverCount = useRef(0)
  const q = gsap.utils.selector(navRef)
  const navAnimations = useRef()
  const [isNavOpen, setIsNavOpen] = useState(false)
  const { handleFooterClose } = useFooter()
  const [isNavAnimating, setIsNavAnimating] = useState(false)

  useEffect(() => {
    navAnimations.current = {
      openNav: () => {
        animationRunning.current = true

        setIsNavAnimating(true)
        handleFooterClose()

        gsap
          .timeline()
          .to(q(".nav-circle-overlay"), {
            r: 0,
            duration: 0.8,
            onComplete: () => {
              document.body.classList.add("hide-main")
            },
          })
          .to(q(".nav-link"), {
            y: 0,
            stagger: 0.2,
            ease: "power3.out",
            onComplete: () => {
              animationRunning.current = false
              setIsNavAnimating(false)
            },
          })
      },
      closeNav: () => {
        animationRunning.current = true
        setIsNavAnimating(true)
        document.body.classList.remove("hide-main")

        gsap
          .timeline()
          .set(q(".nav-image"), {
            display: "none",
          })
          .set(q(".nav-image-primary"), {
            display: "none",
          })
          .set(q(".nav-circle"), {
            display: "none",
          })
          .to(
            q(".nav-link"),
            {
              y: "100%",
              stagger: 0.1,
              reversed: true,
              ease: "power3.in",
            },
            0
          )
          .set(q(".nav-circle"), { display: "none" })
          .to(q(".nav-circle-overlay"), {
            r: "60%",
            duration: 0.6,
            onComplete: () => {
              setIsNavAnimating(false)
              setIsNavOpen(false)
              navItemHoverCount.current = 0
              animationRunning.current = false

              gsap.set(q(".nav-image"), {
                clipPath: "polygon(0 0,0 0,0 100%,0% 100%)",
                display: "block",
              })
              gsap.set(q(".nav-image-primary"), {
                clipPath: "polygon(0 0,0 0,0 100%,0% 100%)",
                display: "block",
              })
            },
          })
      },
      navImageIn: callback => {
        if (navItemHoverCount.current === 0) {
          callback(true)

          gsap
            .timeline()
            .set(q(".nav-circle"), { display: "block" })
            .fromTo(
              q(".nav-circle"),
              {
                x: -200,
              },
              {
                x: 0,
                opacity: 1,
                ease: "expo.out",
                duration: 1,
              }
            )
            .to(
              q(".nav-image"),
              {
                clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
              },
              0.2
            )

          navItemHoverCount.current += 1
        } else {
          callback()

          gsap
            .timeline()
            .to(q(".nav-circle"), {
              x: 30,
            })
            .to(q(".nav-circle"), {
              x: 0,
              ease: "expo.out",
              duration: 1,
            })

          gsap
            .timeline()
            .to(q(".nav-image-primary"), {
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
              onComplete: () => callback(true),
            })
            .set(q(".nav-image-primary"), {
              clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
            })
        }
      },
    }
  }, [])

  useEffect(() => {
    if (isNavOpen) navAnimations.current.openNav()
  }, [isNavOpen])

  function handleNavOpen() {
    if (animationRunning.current) return

    document.body.classList.toggle("nav-open")
    setIsNavOpen(true)
  }

  async function handleNavClose() {
    //if (animationRunning.current) return

    document.body.classList.remove("nav-open")
    navAnimations.current.closeNav()
  }

  function handleNavToggle() {
    if (isNavOpen) return handleNavClose()

    handleNavOpen()
  }

  function handleNavItemHover(item) {
    if (animationRunning.current) return

    navAnimations.current.navImageIn(isFirstCallback => {
      if (isFirstCallback) {
        const image = document.querySelector(".nav-image")
        image.src = item.image
      } else {
        const image = document.querySelector(".nav-image-primary")
        image.src = item.image
      }
    })
  }

  return {
    navRef,
    navAnimations,
    isNavOpen,
    isNavAnimating,
    navItems: navItemRef.current,
    handleNavOpen,
    handleNavClose,
    handleNavToggle,
    handleNavItemHover,
  }
}
