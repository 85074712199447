import React, { useState } from "react"
import styled from "styled-components"
import vars from "styles/variables"
import ArrowRightLongIcon from "components/svg/ArrowRightLongIcon"
import ArrowLeftLongIcon from "components/svg/ArrowLeftLongIcon"
import ContentCircleBorderIcon from "components/svg/ContentCircleBorderIcon"
import Button from "components/common/Button"

function FinalDesign({ data }) {
  const { title, finalDesignList } = data.content
  const lastIndex = finalDesignList.length - 1

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [activeSlide, setActiveSlide] = useState(
    finalDesignList ? finalDesignList[0] : null
  )

  function handleChangeSlide(index) {
    setActiveSlide(finalDesignList[index])
    setActiveSlideIndex(index)
  }

  return (
    <StyledFinalDesign>
      {title && <h2 className="gsap-slide-left-1">{title}</h2>}
      {activeSlide && (
        <>
          <div className="outer-circle gsap-scale-up-1"></div>
          <img
            src={activeSlide.image}
            className="background-image gsap-clip-circle-1"
          />
          <div className="content-circle">
            <ContentCircleBorderIcon className="content-border" />
            <div>
              {activeSlide.title && <h3>{activeSlide.title}</h3>}
              {activeSlide.subTitle && <h4>{activeSlide.subTitle}</h4>}
              <div className="content-controls">
                <button
                  onClick={() => handleChangeSlide(activeSlideIndex - 1)}
                  disabled={activeSlideIndex === 0}
                  className="mr-2"
                >
                  <ArrowLeftLongIcon />
                </button>
                <button
                  onClick={() => handleChangeSlide(activeSlideIndex + 1)}
                  disabled={lastIndex === activeSlideIndex}
                  className="ml-2"
                >
                  <ArrowRightLongIcon />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="back-to-projects-wrapper gsap-slide-up-end-1">
        <Button to="/projects">
          Back to Projects
          <ArrowLeftLongIcon />
        </Button>
      </div>
    </StyledFinalDesign>
  )
}

export default FinalDesign

const StyledFinalDesign = styled.div`
  position: relative;

  h2 {
    position: absolute;
    bottom: 10%;
    left: 10%;
    z-index: 50;
    font-style: normal;
    font-weight: 600;
    font-size: 89px;
    line-height: 84px;
    letter-spacing: 4px;
  }

  .background-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    clip-path: circle(10%);
  }

  .outer-circle {
    width: 800px;
    height: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    z-index: 30;
    border: 3px solid ${vars.grey_300};
    border-radius: 50%;
  }

  .back-to-projects-wrapper {
    position: absolute;
    bottom: 25px;
    right: 0;
    transform: translate(0, 4rem);

    svg {
      margin-left: 1rem;
      path {
        fill: ${vars.white};
      }
    }
  }

  .content-circle {
    width: 330px;
    height: 330px;
    border-radius: 50%;
    background: ${vars.paleGreen};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 50;

    .content-border {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    button {
      all: unset;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 120%;
      margin: 0 0 1rem 0;
    }

    h4 {
      font-family: ${vars.tertiaryFont};
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      line-height: 120%;
      margin: 0;
    }

    .content-controls {
      position: absolute;
      width: 100%;
      bottom: 3rem;
      left: 0;
    }
  }
`
