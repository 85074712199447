import React from "react"
import { Helmet } from "react-helmet"

function Seo({ title, metaDescription }) {
  return (
    <Helmet title={`${title} | i/o atelier`}>
      {metaDescription && <meta name="description" content={metaDescription} />}
    </Helmet>
  )
}

export default Seo
