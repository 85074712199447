export const homeCoreImages = {
  innerLarge: require("assets/images/core-inner-large.jpg"),
  innerSmall: require("assets/images/core-inner-small.jpg"),
  middleLarge: require("assets/images/core-middle-large.jpg"),
  middleSmall: require("assets/images/core-middle-small.jpg"),
  outerLarge: require("assets/images/core-outer-large.jpg"),
  outerSmall: require("assets/images/core-outer-small.jpg"),
}

export const homeBackgroundImage = require("assets/images/core-abstract-large.jpg")

export const noImage = require("assets/images/team-member-placeholder.svg")
