import * as React from "react"

const CircleRocketIcon = props => (
  <svg
    width={115}
    height={115}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#CircleRocketIcon)">
      <path
        d="M57.5 115C25.798 115 0 89.202 0 57.5S25.798 0 57.5 0 115 25.798 115 57.5 89.202 115 57.5 115Zm0-109.565C28.793 5.449 5.45 28.793 5.45 57.5c0 28.707 23.357 52.051 52.05 52.051 28.693 0 52.051-23.358 52.051-52.051S86.207 5.45 57.5 5.45v-.015Z"
        fill="#A8D7B0"
      />
      <path
        d="M70.044 72.031c-1.604-2.824-3.746-5.733-6.414-7.407a43.667 43.667 0 0 0 2.057-8.103c4.045 5.42 4.357 11.225 4.357 11.296v4.214Zm-10.998-4.654H57.13v-2.143c0-.837-.695-1.533-1.532-1.533-.837 0-1.533.681-1.533 1.533v2.058H51.54c-2.284-4.655-3.519-10.459-3.519-16.419 0-9.394 3.278-18.476 8.018-22.918 4.427 4.967 7.11 13.14 7.11 21.84 0 6.485-1.448 12.672-4.13 17.567M40.91 72.017v-4.13c0-.07.312-5.96 4.427-11.38.383 2.895.993 5.648 1.916 8.173-2.668 1.675-4.81 4.584-6.343 7.337Zm32.156-4.271c0-.383-.455-8.7-6.954-15.354.071-.838.071-1.76.071-2.597 0-10.232-3.363-19.555-9.01-25.047l-.923-.837-.994.766C49.07 29.261 44.941 39.79 44.941 50.873c0 .454 0 .922.07 1.377-6.64 6.726-7.109 15.198-7.109 15.58v7.025c0 1.15.767 2.058 1.83 2.285.157.07.384.07.54.07.922 0 1.76-.538 2.143-1.376 1.76-3.817 3.817-6.726 5.96-8.244.298.61.61 1.29.922 1.915l.454.838 4.357.07v3.818c0 .837.681 1.532 1.533 1.532.85 0 1.532-.695 1.532-1.532v-3.817l3.661.07.454-.766c.384-.681.838-1.447 1.15-2.214 2.142 1.533 4.356 4.428 6.187 8.401a2.358 2.358 0 0 0 2.143 1.377c.156 0 .383 0 .539-.071 1.064-.227 1.83-1.22 1.83-2.285l-.07-7.18ZM55.615 79.51c-.838 0-1.533.68-1.533 1.532v9.011c0 .837.681 1.533 1.533 1.533.851 0 1.532-.696 1.532-1.533v-9.01c0-.838-.681-1.534-1.532-1.534Z"
        fill="#6FC282"
      />
      <path
        d="M50.122 72.797c-.837 0-1.532.681-1.532 1.533v9.01c0 .838.681 1.534 1.532 1.534.852 0 1.533-.696 1.533-1.533V74.33c0-.837-.681-1.533-1.533-1.533ZM61.118 72.797c-.837 0-1.532.681-1.532 1.533v9.01c0 .838.681 1.534 1.532 1.534.852 0 1.533-.696 1.533-1.533V74.33c0-.837-.681-1.533-1.533-1.533ZM58.295 47.978c0 1.22-.994 2.214-2.214 2.214a2.217 2.217 0 0 1-2.214-2.214c0-1.22.994-2.214 2.214-2.214s2.214.994 2.214 2.214Zm-2.214-5.194a5.272 5.272 0 0 0-5.265 5.265c0 2.895 2.37 5.265 5.265 5.265 2.895 0 5.265-2.37 5.265-5.265-.071-2.895-2.37-5.265-5.265-5.265Z"
        fill="#6FC282"
      />
    </g>
    <defs>
      <clipPath id="CircleRocketIcon">
        <path fill="#fff" d="M0 0h115v115H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default CircleRocketIcon
