import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import ProjectBackgroundCircle from "components/core/ProjectBackgroundCircle"

function ContentCircle({ data }) {
  const { title, htmlContent, image, heroText1, heroText2 } = data.content

  return (
    <StyledContentCircle>
      <ProjectBackgroundCircle top="6%" left="36%" />
      {heroText1 && (
        <h3 className="gsap-slide-left-2">
          <span>{heroText1}</span>
          {heroText2 && <span className="rotate">{heroText2}</span>}
        </h3>
      )}
      <div className="content-wrapper">
        <Container>
          <Row className="align-items-center">
            <Col lg="4" className="gsap-slide-left-3 offset-8">
              {title && <h2>{title}</h2>}
              {htmlContent && (
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              )}
            </Col>
          </Row>
        </Container>
        {image && (
          <img
            src={image}
            className="img-fluid content-circle-image gsap-slide-left-2"
          />
        )}
      </div>
    </StyledContentCircle>
  )
}

export default ContentCircle

const StyledContentCircle = styled.div`
  padding: 0 10vw;
  display: flex;
  align-items: center;
  height: 100%;

  .content-wrapper {
    position: relative;
    z-index: 50;
  }

  .container {
    z-index: 50;
    margin: 3rem 0 3rem 10%;
  }

  h2 {
    font-family: ${vars.tertiaryFont};
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
  }

  h3 {
    font-weight: 600;
    font-size: 89.0443px;
    line-height: 84px;
    letter-spacing: 4px;
    white-space: nowrap;
    position: absolute;
    bottom: 10%;
    left: 20%;
    z-index: 60;

    span {
      display: inline-block;

      &.rotate {
        transform: rotate(-90deg);
        transform-origin: left;
        position: relative;
        bottom: -33px;
        left: 25px;
      }
    }
  }

  .content-circle-image {
    position: relative;
    left: 55%;
  }
`
