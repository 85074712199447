import React from "react"

function ContentCircleBorderIcon({ ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="313"
      height="313"
      fill="none"
      viewBox="0 0 313 313"
      {...rest}
    >
      <circle
        cx="156.344"
        cy="156.331"
        r="109.21"
        stroke="url(#paint0_radial_813_3330)"
        strokeWidth="93.8"
      ></circle>
      <defs>
        <radialGradient
          id="paint0_radial_813_3330"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(90 .006 156.338) scale(156.11)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.891" stopColor="#fff" stopOpacity="0.045"></stop>
          <stop offset="0.932" stopColor="#fff"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  )
}

export default ContentCircleBorderIcon
