import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import CircleBulbIcon from "components/svg/CircleBulbIcon"
import CircleHandHeartIcon from "components/svg/CircleHandHeartIcon"
import CircleHandLeafIcon from "components/svg/CircleHandLeafIcon"
import CircleRocketIcon from "components/svg/CircleRocketIcon"
import vars from "styles/variables"

function WhyChooseUs({ isAnimationActive = true }) {
  return (
    <Container
      fluid
      className="mb-5"
      {...(isAnimationActive ? { "data-scroll-section": true } : null)}
    >
      <Row>
        <Col>
          <Container>
            <Row>
              <Col>
                <StyledCircle />
                <StyledContentOverlay
                  style={{ paddingTop: 100 }}
                  className="text-center"
                >
                  <h2
                    {...(isAnimationActive ? { "data-scroll": true } : null)}
                    data-scroll-speed="4"
                  >
                    Why choose us?
                  </h2>
                  <StyledParagraph
                    className="mb-4"
                    {...(isAnimationActive ? { "data-scroll": true } : null)}
                    data-scroll-speed="3"
                  >
                    We love what we do and it shows in our impressive portfolio,
                    which stands as a testimony to the outcomes we've achieved
                    and our passion for innovation and continuous improvement.
                  </StyledParagraph>

                  <Row
                    {...(isAnimationActive ? { "data-scroll": true } : null)}
                    data-scroll-speed="2"
                    className="mb-5"
                  >
                    <Col xs="6" md="3" className="mb-4">
                      <p>
                        <CircleBulbIcon />
                      </p>
                      <strong>We're creative thinkers</strong>
                    </Col>
                    <Col xs="6" md="3" className="mb-4">
                      <p>
                        <CircleRocketIcon />
                      </p>
                      <strong>We're innovators</strong>
                    </Col>
                    <Col xs="6" md="3" className="mb-4">
                      <p>
                        <CircleHandLeafIcon />
                      </p>
                      <strong>
                        We're passionate about a sustainable future
                      </strong>
                    </Col>
                    <Col xs="6" md="3" className="mb-4">
                      <p>
                        <CircleHandHeartIcon />
                      </p>
                      <strong>We're honest and trustworthy</strong>
                    </Col>
                  </Row>
                </StyledContentOverlay>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default WhyChooseUs

const StyledCircle = styled.div`
  position: absolute;
  z-index: 10;
  left: -40%;
  width: 180%;
  display: block;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 50%;
  top: 0;
  aspect-ratio: 1 / 1;

  @media (max-width: ${vars.screen_sm_max}) {
    width: 300%;
    left: -100%;
  }
`

const StyledContentOverlay = styled.div`
  position: relative;
  z-index: 20;
  mix-blend-mode: multiply;
  padding-top: 200px;
`
const StyledParagraph = styled.p`
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
`
