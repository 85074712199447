import { toast } from "react-toastify"

const defaultConfig = {
  position: "bottom-left",
}

export function toastSuccess(text, config) {
  toast.success(text, { ...defaultConfig, ...config })
}

export function toastError(text, config) {
  toast.error(text || "Something went wrong", {
    autoClose: false,
    ...defaultConfig,
    ...config,
  })
}

export function toastInfo(text, config) {
  toast.info(text, { ...defaultConfig, ...config })
}
