import * as React from "react"
import vars from "styles/variables"

function MenuIcon({ color = vars.black, className, ...rest }) {
  return (
    <svg
      width={34}
      height={38}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`menu-icon ${className}`}
      {...rest}
    >
      <path
        d="M6.085 12.941c1.296.003 2.594.01 3.89.004 5.098-.024 10.197-.053 15.297-.08 2.183-.013 4.366-.036 6.55-.042.261 0 .418-.059.518-.327.198-.528.422-1.05.663-1.56.146-.314.041-.367-.257-.364-1.992.02-3.981.027-5.972.038l-19.459.108c-.09 0-.179-.01-.268-.017l-.96 2.241M3.945 19.905c1.296.003 2.594.01 3.89.004 5.097-.025 10.197-.053 15.296-.08 2.183-.013 4.367-.036 6.55-.042.261 0 .418-.059.519-.327a20.2 20.2 0 0 1 .662-1.56c.147-.314.042-.367-.257-.364-1.992.019-3.98.026-5.971.037-6.487.037-12.974.072-19.46.11-.089 0-.178-.012-.267-.018l-.96 2.24M1.21 27.349c1.296.002 2.594.01 3.89.003 5.098-.024 10.197-.052 15.297-.08 2.183-.013 4.366-.036 6.55-.041.261 0 .418-.06.518-.328.198-.528.422-1.05.663-1.56.146-.314.041-.367-.257-.364-1.992.02-3.981.027-5.972.038l-19.459.109c-.09 0-.179-.012-.268-.018l-.96 2.241"
        fill={color}
      />
    </svg>
  )
}

export default MenuIcon
