import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import ImgRecycle from "assets/images/enviro-recycle.png"
import ImgSlotGroove from "assets/images/enviro-slot-groove.png"
import ImgModularity from "assets/images/enviro-modularity.png"
import ImgExperiment from "assets/images/enviro-experiment.png"
import vars from "styles/variables"
import ArrowCurvedRight from "components/svg/ArrowCurvedRight"

export default function EnvironmentSlider() {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return

    // This does not work without a settimeout
    setTimeout(() => {
      let sections = gsap.utils.toArray(".environment-item-wrapper")

      ScrollTrigger.matchMedia({
        "(min-width: 992px)": function () {
          gsap.to(sections, {
            xPercent: -100 * (sections.length - 1),
            ease: "none",
            scrollTrigger: {
              id: "enviro-slider",
              start: "top top",
              trigger: ref.current,
              scroller: "#main-container",
              pin: true,
              scrub: 0.5,
              end: () => `+=${ref.current.offsetWidth / 2}`,
            },
          })
          ScrollTrigger.refresh()
        },
      })
    })

    return () => {
      const scrollTriggerToKill = ScrollTrigger.getById("enviro-slider")
      if (scrollTriggerToKill) {
        scrollTriggerToKill.kill(true)
      }
    }
  }, [])

  return (
    <StyledEnvironmentSlider ref={ref}>
      {data.map((item, index) => {
        const isLast = data.length === index + 1
        return (
          <div key={item.id} className="environment-item-wrapper">
            <div className="environment-item">
              {!isLast && <StyledArrowTop />}
              <StyledCircle>
                <h3>
                  <StyledBadge>{item.id}</StyledBadge> {item.title}
                </h3>
                <p>{item.content}</p>
                <StyledImg src={item.image} alt={item.title} />
              </StyledCircle>
              {!isLast && <StyledArrowBottom />}
            </div>
          </div>
        )
      })}
    </StyledEnvironmentSlider>
  )
}

const data = [
  {
    id: 1,
    title: "Recycle",
    content:
      "Use natural materials that can break apart while combining different parts or materials by using an interlocking form fit approach",
    image: ImgRecycle,
  },
  {
    id: 2,
    title: "Slot and Groove",
    content:
      "Use the Japanese technique to create 3D shapes and forms without the need of glues or permanent fixings. Create pieces by using a folding and collapsable approach",
    image: ImgSlotGroove,
  },
  {
    id: 3,
    title: "Modularity",
    content:
      "Look for the opportunities to create or use existing modular structures. These could be created by connection pieces or the structural element themselves.",
    image: ImgModularity,
  },
  {
    id: 4,
    title: "Experiment",
    content:
      "Use organic materials and new technologies to achieve carbon nutrality withing our specifications.",
    image: ImgExperiment,
  },
]

const StyledEnvironmentSlider = styled.div`
  height: 80vh;
  padding: 10vh 8vw;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${vars.screen_md_max}) {
    overflow-x: scroll;
    padding: 0 0 40px 44px;
    height: 660px;
  }

  @media (max-width: ${vars.screen_xs_max}) {
    height: 475px;
  }

  .environment-item-wrapper {
    height: 100%;
    display: grid;
    width: 50vw;
    padding: 0 5vw;
    position: relative;

    @media (max-width: ${vars.screen_md_max}) {
      width: 100vw;
    }
  }

  .environment-item {
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
  }
`

const StyledCircle = styled.div`
  width: 500px;
  height: 500px;
  background: #f1f7f2;
  border-radius: 50%;
  margin: 0 auto;
  padding: 80px 55px 80px 150px;
  position: relative;

  @media (max-width: ${vars.screen_xs_max}) {
    width: 350px;
    height: 350px;
    padding: 55px 36px 36px 114px;
  }

  h3 {
    position: relative;
  }
`

const StyledImg = styled.img`
  max-width: 332px;
  width: 100%;
  position: absolute;
  bottom: -100px;
  left: -100px;

  @media (max-width: ${vars.screen_xs_max}) {
    max-width: 160px;
    bottom: -50px;
    left: -55px;
  }
`

const StyledBadge = styled.span`
  position: absolute;
  width: 34px;
  height: 34px;
  top: 0;
  left: -50px;
  background: ${vars.black};
  color: ${vars.white};
  border-radius: 50%;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${vars.secondaryFont};
`

const StyledArrowTop = styled(ArrowCurvedRight)`
  position: absolute;
  top: 0;
  right: -100px;
`

const StyledArrowBottom = styled(ArrowCurvedRight)`
  position: absolute;
  bottom: 0;
  right: -36px;
  transform: rotate(180deg);
`
