import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import MenuIcon from "components/svg/MenuIcon"
import Link from "components/common/Link"
import { useNav } from "hooks/useNav"
import NavOverlay from "components/svg/NavOverlay"
import NavCircle from "components/svg/NavCircle"
import Logo from "components/svg/DefaultLogo"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import vars from "styles/variables"
import { useFooter } from "hooks/useFooter"

function MainNav() {
  const history = useHistory()

  const {
    navRef,
    isNavOpen,
    navItems,
    handleNavToggle,
    handleNavClose,
    handleNavItemHover,
  } = useNav()

  const { handleFooterOpen } = useFooter()

  function handleNavClick(item) {
    handleNavClose()

    if (item.url === "/contact") handleFooterOpen()
    else history.push(item.url)
  }

  return (
    <>
      <nav ref={navRef}>
        <StyledMenuIcon
          onClick={handleNavToggle}
          onMouseEnter={enlargeCursorSize}
          onMouseLeave={resetCursorSize}
        />

        <StyledOverlay
          className="nav-overlay"
          isNavOpen={isNavOpen}
          onClick={handleNavClose}
        >
          <NavOverlay />
          <StyledNav className="nav">
            {navItems.map((item, index) => (
              <StyledNavItemContainer
                key={index}
                className="nav-link-container"
              >
                <StyledNavItem
                  className="nav-link"
                  onClick={() => handleNavClick(item)}
                  onMouseEnter={() => {
                    enlargeCursorSize()
                    handleNavItemHover(item)
                  }}
                  onMouseLeave={resetCursorSize}
                >
                  {item.title}
                </StyledNavItem>
              </StyledNavItemContainer>
            ))}
          </StyledNav>
          <StyledNavCircle />
          <StyledNavImage className="nav-image" />
          <StyledNavImage className="nav-image-primary" />
        </StyledOverlay>
      </nav>
      <Link
        // to="/"
        onClick={() => {
          handleNavClose()
          window.location.href = "/"
        }}
        onMouseEnter={enlargeCursorSize}
        onMouseLeave={resetCursorSize}
      >
        <StyledLogo />
      </Link>
    </>
  )
}

export default MainNav

const StyledOverlay = styled.div`
  display: ${props => (props.isNavOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  overflow: hidden;
`

const StyledNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 120px 0 0 75px;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &:hover {
    .nav-link-container {
      opacity: 0.2;
    }
  }
`

const StyledNavItemContainer = styled.div`
  overflow: hidden;
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transform-origin: 0 50%;

  &:hover {
    transform: scale(1.2);
    opacity: 1 !important;
  }
`

const StyledNavItem = styled(Link)`
  font-size: 24px;
  display: block;
  transform: translate(0px, 100%);
  padding: 25px 0;
  line-height: 1;
`
const StyledNavImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 60vw;
  height: 100vh;
  object-fit: cover;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);

  &.nav-image-primary {
    z-index: 2;
  }

  @media (max-width: ${vars.screen_md_max}) {
    display: none !important;
  }
`

const StyledNavCircle = styled(NavCircle)`
  position: absolute;
  top: 0;
  right: 50vw;
  z-index: 5;
  height: 100%;
  display: none;

  @media (max-width: ${vars.screen_lg_max}) {
    right: 46vw;
  }

  @media (max-width: ${vars.screen_md_max}) {
    display: none !important;
  }
`

const StyledMenuIcon = styled(MenuIcon)`
  position: relative;
  z-index: 220;
  margin-left: 15px;
`

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 220;
  margin-left: 25px;
`
