import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import vars from "styles/variables"
import ProjectBackgroundCircle from "components/core/ProjectBackgroundCircle"

function IntroBlock({ data }) {
  const { title, subTitle, htmlContent, image } = data.content

  return (
    <StyledIntroBlock>
      <ProjectBackgroundCircle top="6%" left="36%" />
      <Container>
        <Row className="align-items-center">
          <Col className="gsap-slide-left-2">
            <span className="into-title">
              <div>t</div>
              <div>h</div>e story
            </span>
            {subTitle && <h3>{subTitle}</h3>}
          </Col>
          <Col className="gsap-slide-left-3">
            {title && <h2>{title}</h2>}
            {htmlContent && (
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}
          </Col>
        </Row>
        {image && (
          <Row className="justify-content-end">
            <Col xs="auto">
              <img
                src={image}
                alt=""
                className="banner-image gsap-slide-left-1"
              />
            </Col>
          </Row>
        )}
      </Container>
    </StyledIntroBlock>
  )
}

export default IntroBlock

const StyledIntroBlock = styled.div`
  padding: 0 10vw;
  display: flex;
  align-items: center;
  height: 100%;

  .container {
    z-index: 50;
  }

  h2 {
    font-family: ${vars.secondaryFont};
    font-weight: 300;
    font-size: 40px;
    line-height: 120%;
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    margin-top: 1.5rem;
  }

  .into-title {
    font-weight: 600;
    font-size: 89px;
    line-height: 1;
  }

  .banner-image {
    width: 500px;
    height: 282px;
    object-fit: cover;
    margin: 2rem 5rem -125px 0;
  }
`
