import * as React from "react"

const SvgComponent = ({ className = "", ...rest }) => (
  <svg
    width={1195}
    height={899}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`nav-circle ${className}`}
    {...rest}
  >
    <path
      d="M192.908-209.1c-393.564 0-702.003 307.41-702.542 700.108-.539 379.472 311.403 694.172 688.265 694.172 385.482 0 702.003-312.001 702.272-692.553.539-382.711-312.211-701.727-687.995-701.457v-.27ZM-824 487.229C-824-82.519-376.291-525.687 200.72-522.988 717.929-520.559 1194.73-105.73 1195 489.118c0 583.512-472.492 1016.962-1026.336 1011.832-547.38-5.12-992.933-455.85-992.664-1013.721Z"
      fill="#f2f2f2"
    />
  </svg>
)

export default SvgComponent
