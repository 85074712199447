import React from "react"
import styled from "styled-components"
import vars from "styles/variables"

export default function ProjectBackgroundCircle({
  colour = vars.grey_200,
  top = 0,
  left = 0,
  right = "unset",
  bottom = "unset",
  centerVertically,
  ...rest
}) {
  return (
    <StyledCircle
      colour={colour}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      centerVertically={centerVertically}
      {...rest}
    />
  )
}

const StyledCircle = styled.div`
  width: 1000px;
  height: 1000px;
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  background: ${props => props.colour};
  border-radius: 50%;
  z-index: 20;

  ${props =>
    props.centerVertically && "top:50%; transform: translate(0, -50%);"}
`
