import config from "config"
import httpService from "services/httpService"

const endpoint = `${config.api}/Projects`

export async function getProjectList() {
  const data = await httpService.get(`${endpoint}/ProjectList`)
  return data
}

export async function getProjectDetails(slug) {
  const data = await httpService.get(`${endpoint}/ProjectDetails`, {
    params: { slug },
  })
  return data
}
