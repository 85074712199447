import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import PageTemplate from "templates/PageTemplate"
import useLocoScroll from "hooks/useLocoScroll"
import { Link } from "react-router-dom"
import { enlargeCursorSize, resetCursorSize } from "utils/animationUtil"
import useAboutUs from "hooks/useAboutUs"
import useImagePreloader from "hooks/useImagePreloader"
import ImgAboutUsCircle from "assets/images/about-us-circle.png"
import ImgAbout1 from "assets/images/about-image-1-v2.png"
import ImgAbout2 from "assets/images/about-image-2.png"
import ImgAbout3 from "assets/images/about-image-3.png"
import ImgNarrative1 from "assets/images/narrative-1.png"
import ImgNarrative2 from "assets/images/narrative-2.png"
import ImgNarrative3 from "assets/images/narrative-3.png"
import ImgNarrative4 from "assets/images/narrative-4.png"
import ImgApproachCircle from "assets/images/approach-circle.png"
import ImgOurMission from "assets/images/our-mission-banner-v2.png"
import ImgHumanCentric from "assets/images/human-centric-circle.png"
import OurProcessInfographic from "components/svg/OurProcessInfographic"
import Seo from "components/common/Seo"
import WhyChooseUs from "components/core/WhyChooseUs"
import BackToTop from "components/common/BackToTop"

const preloadSrcList = [
  ImgAboutUsCircle,
  ImgAbout1,
  ImgAbout2,
  ImgAbout3,
  ImgNarrative1,
  ImgNarrative2,
  ImgNarrative3,
  ImgNarrative4,
  ImgApproachCircle,
  ImgOurMission,
  ImgHumanCentric,
]

function AboutUsPage() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const { aboutUsRef } = useAboutUs(imagesPreloaded)

  const { locoScrollTop } = useLocoScroll(imagesPreloaded)

  // TODO: Add a preload animation to page, ideally we need a lottie animation
  if (!imagesPreloaded) return null
  //return <LoadingIcon colour="primary" width={200} isFullScreen />

  return (
    <StyledAboutUsPage ref={aboutUsRef}>
      <Seo title="About Us" />
      <BackToTop onClick={locoScrollTop} />
      <PageTemplate title="About us" hideTitle>
        <Container>
          <Row className="mb-5" data-scroll-section>
            <Col xs="12" lg="6">
              <StyledAboutUs className="about-us-title">
                <div
                  data-scroll
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                  style={{ marginBottom: 2 }}
                >
                  <span>a</span>
                </div>
                <div
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                  style={{ marginBottom: -6, marginLeft: -1 }}
                >
                  <span>b</span>
                </div>
                <div
                  data-scroll
                  data-scroll-speed="0.5"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                >
                  <span>out us</span>
                </div>
              </StyledAboutUs>

              <div className="about-us-content">
                <p>
                  Architecture and design, when done well, brings out the best
                  in people. It's about creating emotional and physical
                  experiences, and it's about nurturing the thoughts and
                  feelings that make us human.
                </p>
                <p>
                  These convictions are what brought us four bold creatives and
                  thinkers together. But there was another thing we all shared:
                  a belief that to make beautiful, human, soulful places you
                  needed more than an artist's vision. You needed a scientist's
                  surgical precision. And we all happened to be digital natives,
                  fascinated by the future telling possibilities of hard data
                  and ready to crunch the numbers.
                </p>
                <p>
                  So now that's what we do: we blend creativity and hard data to
                  design buildings and places that bring out the very best in
                  people. Joining forces under one name and with one purpose has
                  felt like the most natural thing in the world.
                </p>
              </div>

              <div className="about-circle-image">
                <img
                  src={ImgAboutUsCircle}
                  alt=""
                  className="img-fluid mt-5 margin-center d-none d-lg-block"
                  loading="lazy"
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                />
              </div>
            </Col>
            <Col className="col-xl-offset-1 mt-5">
              <StyledAboutImages className="about-images">
                <Link
                  to="/meet-the-team"
                  onMouseEnter={enlargeCursorSize}
                  onMouseLeave={resetCursorSize}
                >
                  <img
                    src={ImgAbout1}
                    alt=""
                    className="img-fluid img-about-1"
                    loading="lazy"
                    data-scroll
                    data-scroll-speed="2"
                  />
                </Link>

                <img
                  src={ImgAbout2}
                  alt=""
                  className="img-fluid img-about-2"
                  loading="lazy"
                  data-scroll
                  data-scroll-speed="-3"
                  data-scroll-direction="horizontal"
                />
                <img
                  src={ImgAbout3}
                  alt=""
                  className="img-fluid img-about-3"
                  loading="lazy"
                  data-scroll
                  data-scroll-speed="4"
                />

                <StyledBackgroundCircle
                  data-scroll
                  data-scroll-speed="-2"
                  data-scroll-direction="horizontal"
                />
              </StyledAboutImages>

              <StyledQuoteContainer className="about-first-quote">
                <StyledQuote
                  data-scroll
                  data-scroll-speed="0.8"
                  data-scroll-direction="horizontal"
                >
                  The best way to tell a story is with a beautiful design built
                  upon great data
                </StyledQuote>
                <p
                  className="fs-14px"
                  data-scroll
                  data-scroll-speed="0.5"
                  data-scroll-direction="horizontal"
                >
                  Steven Charlton
                  <br />
                  Founder &amp; CEO
                </p>
              </StyledQuoteContainer>
            </Col>
          </Row>
          <Row className="mb-4" data-scroll-section>
            <Col xs="12" className="mb-2">
              <h2 className="my-5" data-scroll data-scroll-speed="2">
                Our narrative
              </h2>
            </Col>
            <Col>
              <div className="p-0 px-xl-5">
                <Row>
                  <Col xs="12" lg="6">
                    <Row className="align-items-sm-center">
                      <Col xs="5" sm="6" className="mb-5">
                        <img
                          src={ImgNarrative1}
                          alt=""
                          className="img-fluid reveal-up"
                          data-scroll
                          data-scroll-speed="2"
                          data-scroll-repeat={true}
                        />
                      </Col>
                      <Col xs="7" sm="6" className="mb-5 pl-0 pl-sm-3">
                        <StyledBadge data-scroll data-scroll-speed="3">
                          1
                        </StyledBadge>
                        <p
                          className="fs-14px"
                          data-scroll
                          data-scroll-speed="2"
                        >
                          We are architects, creatives, data scientists,
                          original thinkers, futurists and you.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="6">
                    <Row
                      className="align-items-sm-center"
                      style={{ marginTop: 50 }}
                    >
                      <Col xs="5" sm="6" className="mb-5">
                        <img
                          src={ImgNarrative2}
                          alt=""
                          className="img-fluid reveal-up"
                          data-scroll
                          data-scroll-speed="1"
                          data-scroll-repeat={true}
                        />
                      </Col>
                      <Col xs="7" sm="6" className="mb-5 pl-0 pl-sm-3">
                        <StyledBadge data-scroll data-scroll-speed="2">
                          2
                        </StyledBadge>
                        <p
                          className="fs-14px"
                          data-scroll
                          data-scroll-speed="1"
                        >
                          We relish the moments of discovery, disruption,
                          challenge, and serendipity. We relish the moments of
                          connection.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="6">
                    <Row className="align-items-sm-center">
                      <Col xs="5" sm="6" className="mb-5">
                        <img
                          src={ImgNarrative3}
                          alt=""
                          className="img-fluid reveal-up"
                          data-scroll
                          data-scroll-speed="1"
                          data-scroll-repeat={true}
                        />
                      </Col>
                      <Col
                        xs="7"
                        sm="6"
                        className="mb-5 pl-0 pr-0 pl-sm-3 pr-sm-3"
                      >
                        <StyledBadge data-scroll data-scroll-speed="2">
                          3
                        </StyledBadge>
                        <p
                          className="fs-14px"
                          data-scroll
                          data-scroll-speed="1"
                        >
                          Data Science <strong>x</strong> Bold Creativity
                          <br />
                          Hard Numbers <strong>x</strong> Human Truth
                          <br />
                          Today <strong>x</strong> Tomorrow
                          <br />
                          Evidence <strong>x</strong> Opportunity
                          <br />
                          Questions <strong>x</strong> Answers
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="6">
                    <Row
                      className="align-items-sm-center"
                      style={{ marginTop: 50 }}
                    >
                      <Col xs="5" sm="6" className="mb-5">
                        <img
                          src={ImgNarrative4}
                          alt=""
                          className="img-fluid reveal-up"
                          data-scroll
                          data-scroll-speed="2"
                          data-scroll-repeat={true}
                        />
                      </Col>
                      <Col xs="7" sm="6" className="mb-5 pl-0  pl-sm-3">
                        <StyledBadge data-scroll data-scroll-speed="3">
                          4
                        </StyledBadge>
                        <p
                          className="fs-14px"
                          data-scroll
                          data-scroll-speed="2"
                        >
                          It's where we create meaningful, responsible,
                          resilient places. It's where we create spaces that
                          break down barriers. It's where we create human
                          experiences. It's where we create memories.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="mb-5" data-scroll-section>
          <Row>
            <Col>
              <Container>
                <Row>
                  <Col>
                    <StyledCircle />
                    <StyledContentOverlay>
                      <Row>
                        <Col xs="12" md="6" className="order-2 order-md-1">
                          <h2 data-scroll data-scroll-speed="2">
                            Our process and methodogy
                          </h2>
                          <p data-scroll data-scroll-speed="1.5">
                            We have developed an engagement and information
                            capture experience process with a built in rhythm
                            that allows our team to amplify their creative
                            thinking during all the design stages.
                          </p>
                        </Col>
                        <Col className="text-right order-1 order-md-2 mb-4 mb-md-0">
                          <StyledApproachCircleOuter
                            data-scroll
                            data-scroll-speed="3"
                            data-scroll-direction="horizontal"
                          />
                          <StyledApproachCircle
                            src={ImgApproachCircle}
                            alt=""
                            className="img-fluid reveal-circle"
                            data-scroll
                            data-scroll-speed="3"
                            data-scroll-direction="horizontal"
                          />
                        </Col>
                      </Row>

                      <StyledOurProcessInfographicContainer
                        data-scroll
                        data-scroll-speed="3"
                      >
                        <StyledOurProcessInfographic />
                      </StyledOurProcessInfographicContainer>

                      <Row className="mt-5">
                        <Col xs="12" md="6">
                          <h2 data-scroll data-scroll-speed="2">
                            Creating a sustainable environment
                          </h2>
                          <p data-scroll data-scroll-speed="1.6">
                            Our ambition for every project is to create a highly
                            sustainable environment which embraces outstanding
                            sustainable practices and minimises environmental
                            impact. In simple terms, we will design with
                            circular economy principles in mind.
                          </p>
                          <p data-scroll data-scroll-speed="1.2">
                            We will start each project as a clean slate and aim
                            to push the boundaries of what's possible as
                            material and supply chain technology catches up with
                            our aspirations. Our design thinking will focus on
                            human wellness and incorporate energy saving
                            strategies, waste reduction, smart technologies and
                            sustainable materials. This will deliver a space
                            that is not only beautiful, but also becomes part of
                            the solution rather than the problem as we aim for a
                            carbon negative future.
                          </p>
                        </Col>
                        <Col className="position-relative">
                          <StyledMissonImage
                            src={ImgOurMission}
                            alt=""
                            className="img-fluid"
                            data-scroll
                            data-scroll-speed="-1.5"
                            data-scroll-direction="horizontal"
                          />
                        </Col>
                      </Row>

                      <Row className="mt-5">
                        <Col xs="12" md="6">
                          <img
                            src={ImgHumanCentric}
                            alt=""
                            className="img-fluid reveal-circle"
                            data-scroll
                            data-scroll-speed="3"
                          />
                        </Col>
                        <Col>
                          <StyledQuoteContainer
                            data-scroll
                            data-scroll-speed="1"
                          >
                            <StyledQuote>
                              Our mission is to create a more intuitive, more
                              meaningful, more human world around us
                            </StyledQuote>
                            <p className="fs-14px">
                              Mehdi Jelokhani
                              <br />
                              Senior Associate
                            </p>
                          </StyledQuoteContainer>
                        </Col>
                      </Row>
                    </StyledContentOverlay>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <WhyChooseUs />
      </PageTemplate>
    </StyledAboutUsPage>
  )
}

export default AboutUsPage

const StyledAboutUsPage = styled.div`
  .about-us-content {
    p {
      opacity: 0;
      transform: translate(60px, 60px);
    }
  }

  .about-images,
  .about-first-quote,
  .about-circle-image {
    opacity: 0;
    transform: translate(0, 80px);
  }
`

const StyledAboutUs = styled.h2`
  font-weight: bold;
  line-height: 1;
  font-size: 56px;
  letter-spacing: 7px;

  span {
    opacity: 0;
    display: inline-block;
    transform: rotate(-45deg);
    transform: translate(40px, 40px);
  }
`

const StyledBadge = styled.div`
  width: 34px;
  height: 34px;
  background: ${vars.black};
  font-size: 24px;
  font-family: ${vars.secondaryFont};
  color: ${vars.white};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 50%;
  margin-bottom: 20px;
`

const StyledCircle = styled.div`
  position: absolute;
  z-index: 10;
  left: -40%;
  width: 180%;
  display: block;
  background: rgba(217, 217, 217, 0.1);
  border-radius: 50%;
  top: 0;
  aspect-ratio: 1 / 1;
`

const StyledContentOverlay = styled.div`
  position: relative;
  z-index: 20;
  mix-blend-mode: multiply;
  padding-top: 200px;
`

const StyledQuoteContainer = styled.div`
  max-width: 400px;
  margin: 50px auto 20px auto;
`

const StyledQuote = styled.div`
  font-size: 46px;
  font-family: ${vars.secondaryFont};
  margin-bottom: 20px;
`

const StyledApproachCircle = styled.img`
  margin-top: -200px;

  @media (max-width: ${vars.screen_sm_max}) {
    margin-top: -244px;
  }
`

const StyledMissonImage = styled.img`
  max-width: 1000px !important;
  position: absolute;

  @media (max-width: ${vars.screen_sm_max}) {
    max-width: 700px !important;
    position: relative;
  }

  @media (max-width: ${vars.screen_sm_max}) {
    max-width: 600px !important;
  }
`

const StyledAboutImages = styled.div`
  position: relative;

  .img-about-1 {
    position: relative;
    z-index: 10;
    margin-top: 60px;
    max-width: 375px;
  }

  .img-about-2 {
    position: absolute;
    left: 40%;
    z-index: 5;
    top: 0;
  }

  .img-about-3 {
    position: absolute;
    top: 60%;
    right: 0;
    z-index: 2;
  }
`

const StyledOurProcessInfographicContainer = styled.div`
  margin: 50px 0;

  @media (max-width: ${vars.screen_xs_max}) {
    margin: 150px 0 50px 0;
  }
`

const StyledOurProcessInfographic = styled(OurProcessInfographic)`
  max-width: 800px;
  margin: 0 auto;
  display: block;
`

const StyledBackgroundCircle = styled.div`
  width: 389px;
  height: 389px;
  border-radius: 50%;
  border: 1px solid ${vars.grey_300};
  position: absolute;
  left: 10%;
  z-index: 1;
  top: 30px;
`

const StyledApproachCircleOuter = styled(StyledBackgroundCircle)`
  height: 494px;
  width: 494px;
  top: -245px;
  left: unset;
  right: -25px;

  @media (max-width: ${vars.screen_md_max}) {
    display: none;
  }
`
