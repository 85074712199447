import TagManager from "react-gtm-module"
import httpService from "services/httpService"

export async function initTagManager() {
  const data = await httpService.get(`/api/Home/TagManagerCode`)

  TagManager.initialize({
    gtmId: data.gtmCode,
  })
}
