import ArrowLeftIcon from "components/svg/ArrowLeftIcon"
import React from "react"
import { Link as ReactLink } from "react-router-dom"
import styled from "styled-components"
import vars from "styles/variables"

function Link({
  to,
  children,
  color = vars.black,
  uppercase,
  underline,
  arrowLeft,
  bold,
  ...rest
}) {
  // Regex assumes that any internal link will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)

  return (
    <StyledLink
      to={internal ? to : { pathname: to }}
      color={color}
      uppercase={uppercase}
      underline={underline}
      arrowLeft={arrowLeft}
      bold={bold}
      {...rest}
    >
      {arrowLeft && <ArrowLeftIcon />}
      {children}
    </StyledLink>
  )
}

export default Link

const StyledLink = styled(
  ({ color, uppercase, arrowLeft, bold, underline, ...rest }) => (
    <ReactLink {...rest} />
  )
)`
  color: ${props => props.color};
  ${props => props.uppercase && "text-transform: uppercase;"}
  ${props =>
    props.underline && "border-bottom: 1px solid black; padding-bottom: 1px;"}

  
  font-weight: ${props => (props.bold ? "bold" : "normal")};

  .svg-fill {
    transition: fill ease-in-out 300ms;
  }

  ${props =>
    props.arrowLeft &&
    ` display: inline-flex;
      align-items: center;
      line-height: 1;`}
`

//transition: ease-in-out 300ms;
