import React, { useState } from "react"
import styled from "styled-components"
import useImagePreloader from "hooks/useImagePreloader"
import ImgBox1 from "assets/images/box1.jpg"
import ImgBox2 from "assets/images/box2.jpg"
import ImgBox3 from "assets/images/box3.jpg"
import vars from "styles/variables"

function DataCreativitySlider(props) {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList)
  const [activeItem, setActiveItem] = useState(data[0])

  function handleActiveItem(id) {
    const item = data.find(x => x.id === id)
    setActiveItem(item)
  }

  return (
    <StyledSlider
      data-scroll
      data-scroll-speed="2"
      data-scroll-direction="horizontal"
    >
      {imagesPreloaded && (
        <>
          <StyledContent>
            <StyledControls>
              {data.map(item => (
                <StyledControlDot
                  key={item.id}
                  className={item.id === activeItem.id ? "active" : ""}
                  onClick={() => handleActiveItem(item.id)}
                >
                  {item.id}
                </StyledControlDot>
              ))}
            </StyledControls>
            <StyledTitle>{activeItem.title}</StyledTitle>
            <p>{activeItem.content}</p>
          </StyledContent>
          {activeItem && (
            <StyledSliderImage src={activeItem.imageSrc} key="slider-image" />
          )}
        </>
      )}
    </StyledSlider>
  )
}

export default DataCreativitySlider

const preloadSrcList = [ImgBox1, ImgBox2, ImgBox3]

const data = [
  {
    id: 1,
    title: "Big data",
    content: "We gather thousands of data sets",
    imageSrc: ImgBox1,
  },
  {
    id: 2,
    title: "Analytics",
    content:
      "We aggregate these data sets into our proprietary ranking metrics",
    imageSrc: ImgBox2,
  },
  {
    id: 3,
    title: "Decisions",
    content:
      "Our team apply weightings and relevance to the data based on our combined experience of designing spaces for humans",
    imageSrc: ImgBox3,
  },
]

const StyledSlider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 650px;
  flex-direction: column;

  @media (max-width: ${vars.screen_md_max}) {
    position: unset;
    min-height: 700px;
  }
`

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 234px;
  align-self: start;

  @media (max-width: ${vars.screen_sm_max}) {
    position: unset;
    width: 100%;
    font-size: 14px;

    p {
      height: 63px;
    }
  }
`

const StyledControls = styled.div`
  display: flex;
  margin: 20px 0;
`

const StyledControlDot = styled.div`
  width: 34px;
  height: 34px;
  background: ${vars.black};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${vars.white};
  margin-right: 16px;
  opacity: 0.2;
  transition: ease-in-out 300ms;
  font-family: ${vars.secondaryFont};

  &:last-of-type {
    margin-right: 0;
  }

  &.active {
    opacity: 1;
  }
`

const StyledSliderImage = styled.img`
  width: 100%;
  max-width: 618px;
`

const StyledTitle = styled.strong`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 8px;
  display: block;
`
