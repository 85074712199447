import React, { useRef, useLayoutEffect } from "react"
import { gsap } from "gsap"
import styled from "styled-components"
import vars from "styles/variables"

function ProjectHome({ data }) {
  const ref = useRef()
  const q = gsap.utils.selector(ref)

  const {
    title,
    subTitle,
    backgroundImage,
    innerCoreImage,
    middleCoreImage,
    outerCoreImage,
  } = data.content

  useLayoutEffect(() => {
    gsap.timeline().to(q(".inner-circle"), {
      opacity: 1,
      duration: 1,
      onComplete: expandCircles,
    })

    function expandCircles() {
      gsap
        .timeline()
        .to(q(".dynamic-circle"), {
          clipPath: function (index, target) {
            return `circle(${target.dataset.defaultClipPath}%)`
          },
          duration: 2,
          ease: "back.out(1.5)",
          onComplete: breath,
        })
        .to(
          q(".circle-thin"),
          {
            height: "130%",
            duration: 1.7,
            ease: "back.out(1.7)",
          },
          0
        )
        .to(
          q(".fade-circle-1"),
          {
            height: "120%",
            duration: 2,
            ease: "back.out(1.7)",
          },
          0
        )
    }

    function breath() {
      gsap
        .timeline()
        .to(q(".dynamic-circle"), {
          clipPath: `circle(+=2%)`,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)

      gsap
        .to(q(".circle-breath"), {
          height: `+=20%`,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)

      gsap
        .to(q(".inner-circle"), {
          scale: 1.05,
          duration: 3,
        })
        .yoyo(true)
        .repeat(-1)
    }
  }, [])

  return (
    <StyledProjectHome ref={ref}>
      {backgroundImage && (
        <div className="animate-fade-in">
          <StyledBackgroundImage
            src={backgroundImage}
            alt=""
            className="gsap-fade-out-1"
          />
        </div>
      )}
      <StyledFadeCircle
        className="fade-circle-1 circle-breath"
        src={require("assets/images/fade-circle-1-white.svg")}
      />
      <StyledCircleThin className="circle-thin circle-breath" />
      <StyledCircle
        className="dynamic-circle"
        image={outerCoreImage}
        data-default-clip-path={46}
        zIndex={10}
      />
      <StyledCircle
        className="dynamic-circle"
        image={middleCoreImage}
        data-default-clip-path={36}
        zIndex={20}
      />
      <StyledCircle
        className="dynamic-circle"
        image={innerCoreImage}
        data-default-clip-path={26}
        zIndex={30}
      />
      <StyledInfoCircle className="inner-circle"></StyledInfoCircle>
      <StyledContentCircle className="content-circle">
        <div>
          <h1>{title}</h1>
          {subTitle && <h2>{subTitle}</h2>}
        </div>
      </StyledContentCircle>
    </StyledProjectHome>
  )
}

export default ProjectHome

const StyledInfoCircle = styled.div`
  background: ${vars.dynamicColour()};
  height: 0;
  max-height: 320px;
  min-height: 230px;
  height: 42%;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 80;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  aspect-ratio: 1 / 1;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 1.4;
  overflow: hidden;
  opacity: 0;
`

const StyledProjectHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 179.5%;
    margin-bottom: 0;
  }

  .dynamic-circle {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 106%;
    display: inline-block;
    position: absolute;
    top: 50%;
    aspect-ratio: 1 / 1;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    max-height: 929px;

    &:hover:after,
    &.active:after {
      background: ${vars.dynamicColour("0")} !important;
    }

    &:after {
      content: "";
      transition: 1s ease-in-out;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

const StyledContentCircle = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 100;
`

const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0.2;
`

const StyledCircle = styled.div`
  background: ${props => (props.image ? `url(${props.image})` : "grey")};
  background-color: white;
  clip-path: circle(0);
  z-index: ${props => props.zIndex || 0};
  min-height: 400px;
`

const StyledCircleThin = styled.div`
  height: 0;
  border: 2px solid #ffffff;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`
const StyledFadeCircle = styled.img`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  height: 0;

  @media (max-width: ${vars.screen_sm_max}) {
    display: none;
  }
`
