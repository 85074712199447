import React from "react"
import ContentCircle from "components/blocks/ContentCircle"
import ContentSlider from "components/blocks/ContentSlider"
import ImageList from "components/blocks/ImageList"
import IntroBlock from "components/blocks/IntroBlock"
import FinalDesign from "components/blocks/FinalDesign"
import ProjectHome from "components/blocks/ProjectHome"
import TextWithFeature from "components/blocks/TextWithFeature"

export default function ProjectBlock({ data }) {
  switch (data.type) {
    case "ProjectHome":
      return <ProjectHome data={data} />
    case "IntroBlock":
      return <IntroBlock data={data} />
    case "TextWithFeature":
      return <TextWithFeature data={data} />
    case "ContentCircle":
      return <ContentCircle data={data} />
    case "ContentSlider":
      return <ContentSlider data={data} />
    case "ImageList":
      return <ImageList data={data} />
    case "FinalDesign":
      return <FinalDesign data={data} />
    default:
      return null
  }
}
